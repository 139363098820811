import AcceptanceModal from "@/components/AcceptanceModal";
import ApplyForJobModal from "@/components/ApplyForJobModal";
import DeleteAccountModal from "@/components/DeleteAccountModal";
import FeedbackModal from "@/components/FeedbackModal";
import IndRegisterForwardModal from "@/components/IndRegisterForwardModal";
import MessageModal from "@/components/MessageModal";
import RegisterInterfaceComponent from "@/components/RegisterInterfaceComponent";
import { NavSection } from "@/types/navbar";
import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";
import { Component } from "react";

export const hideFooterPaths = [
  "/job-seeker/profile",
  "/job-seeker/profile/corporate-information",
  "/job-seeker/profile/cv",
  "/job-seeker/profile/documents",
  "/job-seeker/profile/order",
  "/job-seeker/profile/job-applications",
  "/employer/company-info/job-applications",
  "/job-seeker/profile/settings",
  "/job-seeker/profile/support-center",
  "/employer/company-info",
  "/employer/company-info/corporate-information",
  "/employer/company-info/messages",
  "/employer/company-info/documents",
  "/employer/company-info/create-job-posting",
  "/employer/company-info/job-applications",
  "/employer/company-info/search-workspace",
  "/employer/company-info/settings",
  "/employer/company-info/user-info",
  "/employer/company-info/support-center",
  "/employer/company-info/create-job-posting",
  "/employer/company-info/dashboard",
  "/employer/company-info/user-info",
];

export const companyNavSection: NavSection[] = [
  {
    title: "Firma Bilgileri",
    items: [
      {
        label: "Genel Bakış",
        href: "dashboard",
        iconClass: "icon-material-outline-desktop-mac",
      },

      {
        label: "Kullanıcı Bilgilerim",
        href: "user-info",
        iconClass: "icon-feather-user-check",
      },
      {
        label: "Kurumsal Bilgilerim",
        href: "corporate-information",
        iconClass: "icon-line-awesome-hospital-o",
      },
      {
        label: "Çalışma Arkadaşlarım",
        href: "colleague",
        iconClass: "icon-feather-users",
      },
      {
        label: "Mesajlarım",
        href: "messages",
        iconClass: "icon-material-outline-question-answer",
      },
      {
        label: "Belgelerim",
        href: "documents",
        iconClass: "icon-line-awesome-sticky-note-o",
      },
    ],
  },
  {
    title: "İlan Ayarları",
    items: [
      {
        label: "İş İlanı Oluştur",
        href: "create-job-posting",
        iconClass: "icon-material-outline-library-add",
      },
      {
        label: "Aktif İlanlar",
        href: "activeJobAds",
        iconClass: "icon-line-awesome-calendar-check-o",
      },
      {
        label: "İş Başvuruları",
        href: "job-applications",
        iconClass: "icon-line-awesome-pencil-square",
      },
      {
        label: "İş Gücü Ara",
        href: "search-workspace",
        iconClass: "icon-line-awesome-search",
      },
    ],
  },
  {
    title: "Hesabım",
    items: [
      {
        label: "Ayarlar",
        href: "settings",
        iconClass: "icon-material-outline-settings",
      },
      {
        label: "Destek Merkezi",
        href: "support-center",
        iconClass: "icon-material-outline-contact-support",
      },
      {
        label: "Çıkış Yap",
        href: "#",
        iconClass: "icon-material-outline-power-settings-new",
      },
    ],
  },
];

export const profileNavSection: NavSection[] = [
  {
    title: "Kullanıcı Bilgieri",
    items: [
      {
        label: "Bilgilerim",
        href: "",
        iconClass: "icon-material-outline-dashboard",
      },
      {
        label: "Özgeçmişlerim",
        href: "cv",
        iconClass: "icon-material-outline-question-answer",
      },
      {
        label: "Belgelerim",
        href: "documents",
        iconClass: "icon-material-outline-find-in-page",
      },
      {
        label: "Çalışma Düzenim",
        href: "order",
        iconClass: "icon-material-outline-rate-review",
      },
    ],
  },
  {
    title: "Ayarlar",
    items: [
      {
        label: "Ayarlar",
        href: "settings",
        iconClass: "icon-material-outline-settings",
      },
      {
        label: "Destek Merkezi",
        href: "support-center",
        iconClass: "icon-material-outline-contact-support",
      },
      {
        label: "Çıkış Yap",
        href: "#",
        iconClass: "icon-material-outline-power-settings-new",
      },
    ],
  },
];

export const GENDER_OPTIONS = [
  { value: "man", label: "Erkek" },
  { value: "woman", label: "Kadın" },
  { value: "other", label: "Diğer" },
];

export const COUNTRY_CODE_OPTIONS = [
  { value: "+91", label: "+91" },
  { value: "+92", label: "+92" },
  { value: "+93", label: "+93" },
];

export const COUNTRIES_OPTIONS = [
  { value: "Ankara", label: "Ankara" },
  { value: "İstanbul", label: "İstanbul" },
];

export const DISTRICT_OPTIONS = [
  { value: "Çankaya", label: "Çankaya" },
  { value: "Kızılay", label: "Kızılay" },
];

export const SUBJECT_ACTIVITY_OPTIONS = [
  {
    value: "Elektrik",
    label: "Elektrik",
    icon: "glyphicon icon-material-outline-access-alarm",
  },
  {
    value: "Mühendislik",
    label: "Mühendislik",
    icon: "glyphicon icon-material-outline-account-circle",
  },
  {
    value: "Ulaşım",
    label: "Ulaşım",
    icon: "glyphicon icon-material-outline-cake",
  },
];

export const TAX_PROVINCES_OPTIONS = [
  { value: "adana", label: "Adana" },
  { value: "adiyaman", label: "Adıyaman" },
  { value: "afyonkarahisar", label: "Afyonkarahisar" },
  { value: "agri", label: "Ağrı" },
  { value: "aksaray", label: "Aksaray" },
  { value: "amasya", label: "Amasya" },
  { value: "ankara", label: "Ankara" },
  { value: "antalya", label: "Antalya" },
  { value: "ardahan", label: "Ardahan" },
  { value: "artvin", label: "Artvin" },
  { value: "aydin", label: "Aydın" },
  { value: "balikesir", label: "Balıkesir" },
  { value: "bartin", label: "Bartın" },
  { value: "batman", label: "Batman" },
  { value: "bayburt", label: "Bayburt" },
  { value: "bilecik", label: "Bilecik" },
  { value: "bingol", label: "Bingöl" },
  { value: "bitlis", label: "Bitlis" },
  { value: "bolu", label: "Bolu" },
  { value: "burdur", label: "Burdur" },
  { value: "bursa", label: "Bursa" },
  { value: "canakkale", label: "Çanakkale" },
  { value: "cankiri", label: "Çankırı" },
  { value: "corum", label: "Çorum" },
  { value: "denizli", label: "Denizli" },
  { value: "diyarbakir", label: "Diyarbakır" },
  { value: "duzce", label: "Düzce" },
  { value: "edirne", label: "Edirne" },
  { value: "elazig", label: "Elâzığ" },
  { value: "erzincan", label: "Erzincan" },
  { value: "erzurum", label: "Erzurum" },
  { value: "eskisehir", label: "Eskişehir" },
  { value: "gaziantep", label: "Gaziantep" },
  { value: "giresun", label: "Giresun" },
  { value: "gumushane", label: "Gümüşhane" },
  { value: "hakkari", label: "Hakkâri" },
  { value: "hatay", label: "Hatay" },
  { value: "igdir", label: "Iğdır" },
  { value: "isparta", label: "Isparta" },
  { value: "istanbul", label: "İstanbul" },
  { value: "izmir", label: "İzmir" },
  { value: "kahramanmaras", label: "Kahramanmaraş" },
  { value: "karabuk", label: "Karabük" },
  { value: "karaman", label: "Karaman" },
  { value: "kars", label: "Kars" },
  { value: "kastamonu", label: "Kastamonu" },
  { value: "kayseri", label: "Kayseri" },
  { value: "kirikkale", label: "Kırıkkale" },
  { value: "kirklareli", label: "Kırklareli" },
  { value: "kirsehir", label: "Kırşehir" },
  { value: "kilis", label: "Kilis" },
  { value: "kocaeli", label: "Kocaeli" },
  { value: "konya", label: "Konya" },
  { value: "kutahya", label: "Kütahya" },
  { value: "malatya", label: "Malatya" },
  { value: "manisa", label: "Manisa" },
  { value: "mardin", label: "Mardin" },
  { value: "mersin", label: "Mersin" },
  { value: "mugla", label: "Muğla" },
  { value: "mus", label: "Muş" },
  { value: "nevsehir", label: "Nevşehir" },
  { value: "nigde", label: "Niğde" },
  { value: "ordu", label: "Ordu" },
  { value: "osmaniye", label: "Osmaniye" },
  { value: "rize", label: "Rize" },
  { value: "sakarya", label: "Sakarya" },
  { value: "samsun", label: "Samsun" },
  { value: "siirt", label: "Siirt" },
  { value: "sinop", label: "Sinop" },
  { value: "sivas", label: "Sivas" },
  { value: "sanliurfa", label: "Şanlıurfa" },
  { value: "sirnak", label: "Şırnak" },
  { value: "tekirdag", label: "Tekirdağ" },
  { value: "tokat", label: "Tokat" },
  { value: "trabzon", label: "Trabzon" },
  { value: "tunceli", label: "Tunceli" },
  { value: "usak", label: "Uşak" },
  { value: "van", label: "Van" },
  { value: "yalova", label: "Yalova" },
  { value: "yozgat", label: "Yozgat" },
  { value: "zonguldak", label: "Zonguldak" },
];

export const TAX_DISTRICTS_OPTIONS = [
  { value: "adana", label: "Adana" },
  { value: "adiyaman", label: "Adıyaman" },
  { value: "afyonkarahisar", label: "Afyonkarahisar" },
  { value: "agri", label: "Ağrı" },
  { value: "aksaray", label: "Aksaray" },
  { value: "amasya", label: "Amasya" },
  { value: "ankara", label: "Ankara" },
  { value: "antalya", label: "Antalya" },
  { value: "ardahan", label: "Ardahan" },
  { value: "artvin", label: "Artvin" },
  { value: "aydin", label: "Aydın" },
  { value: "balikesir", label: "Balıkesir" },
  { value: "bartin", label: "Bartın" },
  { value: "batman", label: "Batman" },
  { value: "bayburt", label: "Bayburt" },
  { value: "bilecik", label: "Bilecik" },
  { value: "bingol", label: "Bingöl" },
  { value: "bitlis", label: "Bitlis" },
  { value: "bolu", label: "Bolu" },
  { value: "burdur", label: "Burdur" },
  { value: "bursa", label: "Bursa" },
  { value: "canakkale", label: "Çanakkale" },
  { value: "cankiri", label: "Çankırı" },
  { value: "corum", label: "Çorum" },
  { value: "denizli", label: "Denizli" },
  { value: "diyarbakir", label: "Diyarbakır" },
  { value: "duzce", label: "Düzce" },
  { value: "edirne", label: "Edirne" },
  { value: "elazig", label: "Elâzığ" },
  { value: "erzincan", label: "Erzincan" },
  { value: "erzurum", label: "Erzurum" },
  { value: "eskisehir", label: "Eskişehir" },
  { value: "gaziantep", label: "Gaziantep" },
  { value: "giresun", label: "Giresun" },
  { value: "gumushane", label: "Gümüşhane" },
  { value: "hakkari", label: "Hakkâri" },
  { value: "hatay", label: "Hatay" },
  { value: "igdir", label: "Iğdır" },
  { value: "isparta", label: "Isparta" },
  { value: "istanbul", label: "İstanbul" },
  { value: "izmir", label: "İzmir" },
  { value: "kahramanmaras", label: "Kahramanmaraş" },
  { value: "karabuk", label: "Karabük" },
  { value: "karaman", label: "Karaman" },
  { value: "kars", label: "Kars" },
  { value: "kastamonu", label: "Kastamonu" },
  { value: "kayseri", label: "Kayseri" },
  { value: "kirikkale", label: "Kırıkkale" },
  { value: "kirklareli", label: "Kırklareli" },
  { value: "kirsehir", label: "Kırşehir" },
  { value: "kilis", label: "Kilis" },
  { value: "kocaeli", label: "Kocaeli" },
  { value: "konya", label: "Konya" },
  { value: "kutahya", label: "Kütahya" },
  { value: "malatya", label: "Malatya" },
  { value: "manisa", label: "Manisa" },
  { value: "mardin", label: "Mardin" },
  { value: "mersin", label: "Mersin" },
  { value: "mugla", label: "Muğla" },
  { value: "mus", label: "Muş" },
  { value: "nevsehir", label: "Nevşehir" },
  { value: "nigde", label: "Niğde" },
  { value: "ordu", label: "Ordu" },
  { value: "osmaniye", label: "Osmaniye" },
  { value: "rize", label: "Rize" },
  { value: "sakarya", label: "Sakarya" },
  { value: "samsun", label: "Samsun" },
  { value: "siirt", label: "Siirt" },
  { value: "sinop", label: "Sinop" },
  { value: "sivas", label: "Sivas" },
  { value: "sanliurfa", label: "Şanlıurfa" },
  { value: "sirnak", label: "Şırnak" },
  { value: "tekirdag", label: "Tekirdağ" },
  { value: "tokat", label: "Tokat" },
  { value: "trabzon", label: "Trabzon" },
  { value: "tunceli", label: "Tunceli" },
  { value: "usak", label: "Uşak" },
  { value: "van", label: "Van" },
  { value: "yalova", label: "Yalova" },
  { value: "yozgat", label: "Yozgat" },
  { value: "zonguldak", label: "Zonguldak" },
];

export const WOMEN_DISTRICTS_OPTIONS = [
  { value: "adana", label: "Adana" },
  { value: "adiyaman", label: "Adıyaman" },
  { value: "afyonkarahisar", label: "Afyonkarahisar" },
  { value: "agri", label: "Ağrı" },
  { value: "aksaray", label: "Aksaray" },
  { value: "amasya", label: "Amasya" },
  { value: "ankara", label: "Ankara" },
  { value: "antalya", label: "Antalya" },
  { value: "ardahan", label: "Ardahan" },
  { value: "artvin", label: "Artvin" },
  { value: "aydin", label: "Aydın" },
  { value: "balikesir", label: "Balıkesir" },
  { value: "bartin", label: "Bartın" },
  { value: "batman", label: "Batman" },
  { value: "bayburt", label: "Bayburt" },
  { value: "bilecik", label: "Bilecik" },
  { value: "bingol", label: "Bingöl" },
  { value: "bitlis", label: "Bitlis" },
  { value: "bolu", label: "Bolu" },
  { value: "burdur", label: "Burdur" },
  { value: "bursa", label: "Bursa" },
  { value: "canakkale", label: "Çanakkale" },
  { value: "cankiri", label: "Çankırı" },
  { value: "corum", label: "Çorum" },
  { value: "denizli", label: "Denizli" },
  { value: "diyarbakir", label: "Diyarbakır" },
  { value: "duzce", label: "Düzce" },
  { value: "edirne", label: "Edirne" },
  { value: "elazig", label: "Elâzığ" },
  { value: "erzincan", label: "Erzincan" },
  { value: "erzurum", label: "Erzurum" },
  { value: "eskisehir", label: "Eskişehir" },
  { value: "gaziantep", label: "Gaziantep" },
  { value: "giresun", label: "Giresun" },
  { value: "gumushane", label: "Gümüşhane" },
  { value: "hakkari", label: "Hakkâri" },
  { value: "hatay", label: "Hatay" },
  { value: "igdir", label: "Iğdır" },
  { value: "isparta", label: "Isparta" },
  { value: "istanbul", label: "İstanbul" },
  { value: "izmir", label: "İzmir" },
  { value: "kahramanmaras", label: "Kahramanmaraş" },
  { value: "karabuk", label: "Karabük" },
  { value: "karaman", label: "Karaman" },
  { value: "kars", label: "Kars" },
  { value: "kastamonu", label: "Kastamonu" },
  { value: "kayseri", label: "Kayseri" },
  { value: "kirikkale", label: "Kırıkkale" },
  { value: "kirklareli", label: "Kırklareli" },
  { value: "kirsehir", label: "Kırşehir" },
  { value: "kilis", label: "Kilis" },
  { value: "kocaeli", label: "Kocaeli" },
  { value: "konya", label: "Konya" },
  { value: "kutahya", label: "Kütahya" },
  { value: "malatya", label: "Malatya" },
  { value: "manisa", label: "Manisa" },
  { value: "mardin", label: "Mardin" },
  { value: "mersin", label: "Mersin" },
  { value: "mugla", label: "Muğla" },
  { value: "mus", label: "Muş" },
  { value: "nevsehir", label: "Nevşehir" },
  { value: "nigde", label: "Niğde" },
  { value: "ordu", label: "Ordu" },
  { value: "osmaniye", label: "Osmaniye" },
  { value: "rize", label: "Rize" },
  { value: "sakarya", label: "Sakarya" },
  { value: "samsun", label: "Samsun" },
  { value: "siirt", label: "Siirt" },
  { value: "sinop", label: "Sinop" },
  { value: "sivas", label: "Sivas" },
  { value: "sanliurfa", label: "Şanlıurfa" },
  { value: "sirnak", label: "Şırnak" },
  { value: "tekirdag", label: "Tekirdağ" },
  { value: "tokat", label: "Tokat" },
  { value: "trabzon", label: "Trabzon" },
  { value: "tunceli", label: "Tunceli" },
  { value: "usak", label: "Uşak" },
  { value: "van", label: "Van" },
  { value: "yalova", label: "Yalova" },
  { value: "yozgat", label: "Yozgat" },
  { value: "zonguldak", label: "Zonguldak" },
];

export const DOCUMENT_TYPES = [{ value: "OTHER", label: "Diğer" }];

export const DocumentUpload_List = [
  { value: "IDENTITY_CARD", label: "Kullanıcı Kimlik Belgesi" },
  {
    value: "PROOF_OF_ADDRESS",
    label: "Kullanıcı Yerleşim Yeri Belgesi (İkametgah)",
  },
  { value: "PASSPORT", label: "Kullanıcı Pasaportu" },
  { value: "TAX_CERTIFICATE", label: "Vergi Levhası" },
  { value: "CIRCULAR_OF_SIGNATURE", label: "İmza Sirküleri" },
  { value: "OTHER", label: "Dİğer" },
];

export const POSITION_OPTIONS = [
  { value: "Müdür", label: "Müdür" },
  { value: "CEO", label: "CEO" },
  { value: "Genel Müdür", label: "Genel Müdür" },
];
export const CandidateSearch_City = [
  { value: "adana", label: "Adana" },
  { value: "adiyaman", label: "Adıyaman" },
  { value: "afyonkarahisar", label: "Afyonkarahisar" },
  { value: "agri", label: "Ağrı" },
  { value: "aksaray", label: "Aksaray" },
  { value: "amasya", label: "Amasya" },
  { value: "ankara", label: "Ankara" },
  { value: "antalya", label: "Antalya" },
  { value: "ardahan", label: "Ardahan" },
  { value: "artvin", label: "Artvin" },
  { value: "aydin", label: "Aydın" },
  { value: "balikesir", label: "Balıkesir" },
  { value: "bartin", label: "Bartın" },
  { value: "batman", label: "Batman" },
  { value: "bayburt", label: "Bayburt" },
  { value: "bilecik", label: "Bilecik" },
  { value: "bingol", label: "Bingöl" },
  { value: "bitlis", label: "Bitlis" },
  { value: "bolu", label: "Bolu" },
  { value: "burdur", label: "Burdur" },
  { value: "bursa", label: "Bursa" },
  { value: "canakkale", label: "Çanakkale" },
  { value: "cankiri", label: "Çankırı" },
  { value: "corum", label: "Çorum" },
  { value: "denizli", label: "Denizli" },
  { value: "diyarbakir", label: "Diyarbakır" },
  { value: "duzce", label: "Düzce" },
  { value: "edirne", label: "Edirne" },
  { value: "elazig", label: "Elâzığ" },
  { value: "erzincan", label: "Erzincan" },
  { value: "erzurum", label: "Erzurum" },
  { value: "eskisehir", label: "Eskişehir" },
  { value: "gaziantep", label: "Gaziantep" },
  { value: "giresun", label: "Giresun" },
  { value: "gumushane", label: "Gümüşhane" },
  { value: "hakkari", label: "Hakkâri" },
  { value: "hatay", label: "Hatay" },
  { value: "igdir", label: "Iğdır" },
  { value: "isparta", label: "Isparta" },
  { value: "istanbul", label: "İstanbul" },
  { value: "izmir", label: "İzmir" },
  { value: "kahramanmaras", label: "Kahramanmaraş" },
  { value: "karabuk", label: "Karabük" },
  { value: "karaman", label: "Karaman" },
  { value: "kars", label: "Kars" },
  { value: "kastamonu", label: "Kastamonu" },
  { value: "kayseri", label: "Kayseri" },
  { value: "kirikkale", label: "Kırıkkale" },
  { value: "kirklareli", label: "Kırklareli" },
  { value: "kirsehir", label: "Kırşehir" },
  { value: "kilis", label: "Kilis" },
  { value: "kocaeli", label: "Kocaeli" },
  { value: "konya", label: "Konya" },
  { value: "kutahya", label: "Kütahya" },
  { value: "malatya", label: "Malatya" },
  { value: "manisa", label: "Manisa" },
  { value: "mardin", label: "Mardin" },
  { value: "mersin", label: "Mersin" },
  { value: "mugla", label: "Muğla" },
  { value: "mus", label: "Muş" },
  { value: "nevsehir", label: "Nevşehir" },
  { value: "nigde", label: "Niğde" },
  { value: "ordu", label: "Ordu" },
  { value: "osmaniye", label: "Osmaniye" },
  { value: "rize", label: "Rize" },
  { value: "sakarya", label: "Sakarya" },
  { value: "samsun", label: "Samsun" },
  { value: "siirt", label: "Siirt" },
  { value: "sinop", label: "Sinop" },
  { value: "sivas", label: "Sivas" },
  { value: "sanliurfa", label: "Şanlıurfa" },
  { value: "sirnak", label: "Şırnak" },
  { value: "tekirdag", label: "Tekirdağ" },
  { value: "tokat", label: "Tokat" },
  { value: "trabzon", label: "Trabzon" },
  { value: "tunceli", label: "Tunceli" },
  { value: "usak", label: "Uşak" },
  { value: "van", label: "Van" },
  { value: "yalova", label: "Yalova" },
  { value: "yozgat", label: "Yozgat" },
  { value: "zonguldak", label: "Zonguldak" },
];

export const Expire_Year = [
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
  { value: "2031", label: "2031" },
  { value: "2032", label: "2032" },
  { value: "2033", label: "2033" },
  { value: "2034", label: "2034" },
  { value: "2035", label: "2035" },
  { value: "2036", label: "2036" },
  { value: "2037", label: "2037" },
  { value: "2038", label: "2038" },
  { value: "2039", label: "2039" },
  { value: "2040", label: "2040" },
  { value: "2041", label: "2041" },
  { value: "2042", label: "2042" },
  { value: "2043", label: "2043" },
  { value: "2044", label: "2044" },
  { value: "2045", label: "2045" },
  { value: "2046", label: "2046" },
  { value: "2047", label: "2047" },
  { value: "2048", label: "2048" },
  { value: "2049", label: "2049" },
  { value: "2050", label: "2050" },
  { value: "2051", label: "2051" },
  { value: "2052", label: "2052" },
  { value: "2053", label: "2053" },
  { value: "2054", label: "2054" },
  { value: "2055", label: "2055" },
  { value: "2056", label: "2056" },
  { value: "2057", label: "2057" },
  { value: "2058", label: "2058" },
  { value: "2059", label: "2059" },
];

export const Expire_Month = [
  { value: "01", label: "Ocak" },
  { value: "02", label: "Şubat" },
  { value: "03", label: "Mart" },
  { value: "04", label: "Nisan" },
  { value: "05", label: "Mayıs" },
  { value: "06", label: "Haziran" },
  { value: "07", label: "Temmuz" },
  { value: "08", label: "Ağustos" },
  { value: "09", label: "Eylül" },
  { value: "10", label: "Ekim" },
  { value: "11", label: "Kasım" },
  { value: "12", label: "Aralık" },
];

export const WORKING_OPTIONS = [
  { value: "FULL_TIME", label: "Tam Zamanlı" },
  { value: "PART_TIME", label: "Yarı Zamanlı" },
  { value: "DAILY", label: "Günlük" },
];
export const JOB_STATUS = {
  WAITING_FOR_APPROVAL: "Onay Bekliyor",
  APPROVED: "Onaylandı",
  REJECTED: "Reddedildi",
  WAITING_FOR_PAYMENT: "Ödeme Bekliyor",
  ACTIVE: "Aktif",
  PAUSED: "Duraklatıldı",
};

export const JOB_APPLICATION_STATUS = {
  APPLIED: "Başvuruldu",
    APPLICATION_UNDER_REVIEW : "Incelemede",
    OFFER_CREATED_BY_IV : "Isveren tarafından teklif oluşturuldu",
    OFFER_SENT_TO_PT : "Calisan tarafından teklif oluşturuldu",
    OFFER_ACCEPTED_BY_PT : "Calisan tarafından teklif kabul edildi",
    OFFER_DECLINED_BY_PT : "Calisan tarafından teklif reddedildi",
    CANCELLED_BY_IV : "Isveren tarafından iptal edildi",
    CANCELLED_BY_PT : "Calisan tarafından iptal edildi",
    DOCUMENTS_REQUESTED: "Belgeler istendi",
    CONTRACTS_WAITING_FOR_SIGNATURE_BY_PT : "Sözleşme imzalamak için bekleniyor",
    CONTRACTS_PREPARED_BY_IV : "Sözleşme hazırlandı",
    CONTRACTS_SIGNED_BY_PT : "Sözleşme calisan tarafından imzalandı",
    CONTRACTS_SIGNED_AND_PAID_BY_IV : "Sözleşme isveren tarafından imzalandı ve ödendi",
    SGK_CONTACTS_REQUIRED : "SGK belgeleri istendi",
    SGK_CONTACTS_SIGNED : "SGK belgeleri imzalandı",
    PT_READY_TO_START_WORK :"Calisan işe başlamaya hazır",
    PT_STARTED_TO_WORK :"Calisan işe başladı",
    PT_FINISHED_WORKING_AND_PAID_BY_IV:"Calisan isini bitirdi ve ödendi",
    JOB_FINISHED_SUCCESSFULLY_PT_PAID : "Is calisan tarafından başarıyla tamamlandı ve ödendi",
    JOB_TERMINATED_BY_IV : "Isveren tarafından iptal edildi",
    JOB_TERMINATED_BY_PT : "Calisan tarafından iptal edildi",
    PT_WAITING_FOR_START_WORK : "Calisan işe başlamayı bekliyor",
    PT_STARTED_WORK: "Calisan işe başladı",
    PT_STARTED_WORK_APPROVED : "Calisan işe başladı ve onaylandı",
    PAYMENT_REQUIRED_FOR_PT : "Calisan için ödeme yapılması gerekiyor",
    SGK_CONTRACTS_REQUIRED : "SGK belgeleri istendi",
    SGK_CONTRACTS_SIGNED : "SGK belgeleri imzalandı",
};

export const JOB_TYPE_OPTIONS = {
  FULL_TIME: "Tam Zamanlı",
  PART_TIME: "Yarı Zamanlı",
  DAILY: "Günlük",
};

export const STAFF_GENDER_OPTIONS = [
  { value: "Farketmez", label: "Farketmez" },
  { value: "Kadın", label: "Kadın" },
  { value: "Erkek", label: "Erkek" },
];

export const NUMBER_OF_PERSONNEL = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
];

export const COMPANY_OPTIONS = [
  { value: "microsoft", label: "MICROSOFT" },
  { value: "apple", label: "APPLE" },
  { value: "hp", label: "HP" },
];

export const JOB_TITLES = [
  { value: "ceo", label: "CEO" },
  { value: "general-manager", label: "Genel Müdür" },
  { value: "worker", label: "İşçi" },
];

export const INDUSTRY_OPTIONS = [
  { value: "security", label: "Güvenlik" },
  { value: "realEstate", label: "Gayrimenkul" },
  { value: "finance", label: "Finans" },
];

export const LANGUAGE_OPTIONS = [
  { value: "english", label: "English" },
  { value: "german", label: "German" },
  { value: "russian", label: "Russian" },
];

export const LANGUAGE_OPTION = [
  { value: "turkish", label: "Türkçe" },
  { value: "english", label: "İngilizce" },
  { value: "german", label: "Almanca" },
];

export const EDUCATION_TYPE_OPTIONS = [
  { value: "formal", label: "Örgün Öğretim" },
  { value: "part-time", label: "İkinci Öğretim" },
  { value: "distance", label: "Uzaktan Eğitim" },
];

export const UNIVERSITY_OPTIONS = [
  { value: "abdullah-gul", label: "Abdullah Gül Üniversitesi" },
  { value: "acibadem", label: "Acıbadem Mehmet Ali Aydınlar Üniversitesi" },
  {
    value: "adana-alparslan-turkes",
    label: "Adana Alparslan Türkeş Bilim ve Teknoloji Üniversitesi",
  },
  { value: "adiyaman", label: "Adıyaman Üniversitesi" },
  { value: "afyon-kocatepe", label: "Afyon Kocatepe Üniversitesi" },
  {
    value: "afyonkarahisar-saglik-bilimleri",
    label: "Afyonkarahisar Sağlık Bilimleri Üniversitesi",
  },
  { value: "agri-ibrahim-cecen", label: "Ağrı İbrahim Çeçen Üniversitesi" },
  { value: "akdeniz", label: "Akdeniz Üniversitesi" },
  { value: "aksaray", label: "Aksaray Üniversitesi" },
  {
    value: "alanya-alaaddin-keykubat",
    label: "Alanya Alaaddin Keykubat Üniversitesi",
  },
  { value: "alanya", label: "Alanya Üniversitesi" },
  { value: "altinbas", label: "Altınbaş Üniversitesi" },
  { value: "amasya", label: "Amasya Üniversitesi" },
  { value: "anadolu", label: "Anadolu Üniversitesi" },
  { value: "ankara-bilim", label: "Ankara Bilim Üniversitesi" },
  {
    value: "ankara-haci-bayram-veli",
    label: "Ankara Hacı Bayram Veli Üniversitesi",
  },
  { value: "ankara-medipol", label: "Ankara Medipol Üniversitesi" },
  {
    value: "ankara-muzik-ve-guzel-sanatlar",
    label: "Ankara Müzik ve Güzel Sanatlar Üniversitesi",
  },
  {
    value: "ankara-sosyal-bilimler",
    label: "Ankara Sosyal Bilimler Üniversitesi",
  },
  { value: "ankara", label: "Ankara Üniversitesi" },
  {
    value: "ankara-yildirim-beyazit",
    label: "Ankara Yıldırım Beyazıt Üniversitesi",
  },
  { value: "antalya-bilim", label: "Antalya Bilim Üniversitesi" },
  { value: "ardahan", label: "Ardahan Üniversitesi" },
  { value: "artvin-coruh", label: "Artvin Çoruh Üniversitesi" },
  { value: "ataturk", label: "Atatürk Üniversitesi" },
  { value: "atilim", label: "Atılım Üniversitesi" },
  { value: "avrasya", label: "Avrasya Üniversitesi" },
  { value: "aydin-adnan-menderes", label: "Aydın Adnan Menderes Üniversitesi" },
  { value: "bahcesehir", label: "Bahçeşehir Üniversitesi" },
  { value: "balikesir", label: "Balıkesir Üniversitesi" },
  {
    value: "bandirma-onyedi-eylul",
    label: "Bandırma Onyedi Eylül Üniversitesi",
  },
  { value: "bartin", label: "Bartın Üniversitesi" },
  { value: "baskent", label: "Başkent Üniversitesi" },
  { value: "batman", label: "Batman Üniversitesi" },
  { value: "bayburt", label: "Bayburt Üniversitesi" },
  { value: "beykoz", label: "Beykoz Üniversitesi" },
  { value: "bezmialem", label: "Bezmialem Vakıf Üniversitesi" },
  { value: "bilecik-seyh-edebali", label: "Bilecik Şeyh Edebali Üniversitesi" },
  { value: "bingol", label: "Bingöl Üniversitesi" },
  { value: "biruni", label: "Biruni Üniversitesi" },
  { value: "bitlis-eren", label: "Bitlis Eren Üniversitesi" },
  { value: "bogazici", label: "Boğaziçi Üniversitesi" },
  {
    value: "bolu-abant-izzet-baysal",
    label: "Bolu Abant İzzet Baysal Üniversitesi",
  },
  {
    value: "burdur-mehmet-akif-ersoy",
    label: "Burdur Mehmet Akif Ersoy Üniversitesi",
  },
  { value: "bursa-teknik", label: "Bursa Teknik Üniversitesi" },
  { value: "bursa-uludag", label: "Bursa Uludağ Üniversitesi" },
  { value: "cag", label: "Çağ Üniversitesi" },
  {
    value: "canakkale-onsekiz-mart",
    label: "Çanakkale Onsekiz Mart Üniversitesi",
  },
  { value: "cankaya", label: "Çankaya Üniversitesi" },
  { value: "cankiri-karatekin", label: "Çankırı Karatekin Üniversitesi" },
  { value: "corum-hitit", label: "Çorum Hitit Üniversitesi" },
  { value: "cukurova", label: "Çukurova Üniversitesi" },
  { value: "demiroglu-bilim", label: "Demiroğlu Bilim Üniversitesi" },
  { value: "dicle", label: "Dicle Üniversitesi" },
  { value: "dogus", label: "Doğuş Üniversitesi" },
  { value: "dokuz-eylul", label: "Dokuz Eylül Üniversitesi" },
  { value: "duzce", label: "Düzce Üniversitesi" },
  { value: "ege", label: "Ege Üniversitesi" },
  { value: "erciyes", label: "Erciyes Üniversitesi" },
  {
    value: "erzincan-binali-yildirim",
    label: "Erzincan Binali Yıldırım Üniversitesi",
  },
  { value: "erzurum-teknik", label: "Erzurum Teknik Üniversitesi" },
  { value: "eskisehir-osmangazi", label: "Eskişehir Osmangazi Üniversitesi" },
  { value: "eskisehir-teknik", label: "Eskişehir Teknik Üniversitesi" },
  {
    value: "fatih-sultan-mehmet-vakif",
    label: "Fatih Sultan Mehmet Vakıf Üniversitesi",
  },
  { value: "fenerbahce", label: "Fenerbahçe Üniversitesi" },
  { value: "firat", label: "Fırat Üniversitesi" },
  { value: "galatasaray", label: "Galatasaray Üniversitesi" },
  { value: "gaziantep", label: "Gaziantep Üniversitesi" },
  {
    value: "gaziantep-islam-bilim",
    label: "Gaziantep İslam Bilim ve Teknoloji Üniversitesi",
  },
  { value: "gebze-teknik", label: "Gebze Teknik Üniversitesi" },
];

export const EDUCATION_OPTIONS = [
  { value: "lisans", label: "Lisans" },
  { value: "önlisans", label: "Önlisans" },
  { value: "yüksek_lisans", label: "Yüksek Lisans" },
];

export const CITY_OPTIONS = [
  { value: "adana", label: "Adana" },
  { value: "adiyaman", label: "Adıyaman" },
  { value: "afyonkarahisar", label: "Afyonkarahisar" },
  { value: "agri", label: "Ağrı" },
  { value: "aksaray", label: "Aksaray" },
  { value: "amasya", label: "Amasya" },
  { value: "ankara", label: "Ankara" },
  { value: "antalya", label: "Antalya" },
  { value: "ardahan", label: "Ardahan" },
  { value: "artvin", label: "Artvin" },
  { value: "aydin", label: "Aydın" },
  { value: "balikesir", label: "Balıkesir" },
  { value: "bartin", label: "Bartın" },
  { value: "batman", label: "Batman" },
  { value: "bayburt", label: "Bayburt" },
  { value: "bilecik", label: "Bilecik" },
  { value: "bingol", label: "Bingöl" },
  { value: "bitlis", label: "Bitlis" },
  { value: "bolu", label: "Bolu" },
  { value: "burdur", label: "Burdur" },
  { value: "bursa", label: "Bursa" },
  { value: "canakkale", label: "Çanakkale" },
  { value: "cankiri", label: "Çankırı" },
  { value: "corum", label: "Çorum" },
  { value: "denizli", label: "Denizli" },
  { value: "diyarbakir", label: "Diyarbakır" },
  { value: "duzce", label: "Düzce" },
  { value: "edirne", label: "Edirne" },
  { value: "elazig", label: "Elâzığ" },
  { value: "erzincan", label: "Erzincan" },
  { value: "erzurum", label: "Erzurum" },
  { value: "eskisehir", label: "Eskişehir" },
  { value: "gaziantep", label: "Gaziantep" },
  { value: "giresun", label: "Giresun" },
  { value: "gumushane", label: "Gümüşhane" },
  { value: "hakkari", label: "Hakkâri" },
  { value: "hatay", label: "Hatay" },
  { value: "igdir", label: "Iğdır" },
  { value: "isparta", label: "Isparta" },
  { value: "istanbul", label: "İstanbul" },
  { value: "izmir", label: "İzmir" },
  { value: "kahramanmaras", label: "Kahramanmaraş" },
  { value: "karabuk", label: "Karabük" },
  { value: "karaman", label: "Karaman" },
  { value: "kars", label: "Kars" },
  { value: "kastamonu", label: "Kastamonu" },
  { value: "kayseri", label: "Kayseri" },
  { value: "kirikkale", label: "Kırıkkale" },
  { value: "kirklareli", label: "Kırklareli" },
  { value: "kirsehir", label: "Kırşehir" },
  { value: "kilis", label: "Kilis" },
  { value: "kocaeli", label: "Kocaeli" },
  { value: "konya", label: "Konya" },
  { value: "kutahya", label: "Kütahya" },
  { value: "malatya", label: "Malatya" },
  { value: "manisa", label: "Manisa" },
  { value: "mardin", label: "Mardin" },
  { value: "mersin", label: "Mersin" },
  { value: "mugla", label: "Muğla" },
  { value: "mus", label: "Muş" },
  { value: "nevsehir", label: "Nevşehir" },
  { value: "nigde", label: "Niğde" },
  { value: "ordu", label: "Ordu" },
  { value: "osmaniye", label: "Osmaniye" },
  { value: "rize", label: "Rize" },
  { value: "sakarya", label: "Sakarya" },
  { value: "samsun", label: "Samsun" },
  { value: "siirt", label: "Siirt" },
  { value: "sinop", label: "Sinop" },
  { value: "sivas", label: "Sivas" },
  { value: "sanliurfa", label: "Şanlıurfa" },
  { value: "sirnak", label: "Şırnak" },
  { value: "tekirdag", label: "Tekirdağ" },
  { value: "tokat", label: "Tokat" },
  { value: "trabzon", label: "Trabzon" },
  { value: "tunceli", label: "Tunceli" },
  { value: "usak", label: "Uşak" },
  { value: "van", label: "Van" },
  { value: "yalova", label: "Yalova" },
  { value: "yozgat", label: "Yozgat" },
  { value: "zonguldak", label: "Zonguldak" },
];

export const COUNTRY_OPTIONS = [
  { value: "turkey", label: "Türkiye" },
  { value: "usa", label: "ABD" },
  { value: "germany", label: "Almanya" },
];

export const POSITION_LEVEL_OPTIONS = [
  { value: "consultant", label: "Danışman" },
  { value: "officeWorker", label: "Ofis Elemanı" },
  { value: "worker", label: "İşçi" },
];

export const EMPLOYMENT_TYPE_OPTIONS = [
  { value: "partTime", label: "Yarı Zamanlı" },
  { value: "fullTime", label: "Tam Zamanlı" },
  { value: "contract", label: "Sözleşmeli" },
];

export const DIPLOMA_OPTIONS = [
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
];

export const DOCUMENT_OPTIONS = [
  { value: "Sürücü Belgesi", label: "Sürücü Belgesi" },
  { value: "Mezuniyet Belgesi", label: "Mezuniyet Belgesi" },
  { value: "Uzmanlık Belgesi", label: "Uzmanlık Belgesi" },
];

export const MODALS_LIST = [
  { id: "feedback-modal", Component: FeedbackModal },
  { id: "delete-account-modal", Component: DeleteAccountModal },
  { id: "acceptance-modal", Component: AcceptanceModal },
  { id: "message-modal", Component: MessageModal },
  { id: "apply-for-job-modal", Component: ApplyForJobModal },
  { id: "login", Component: RegisterInterfaceComponent },
  { id: "ind-register-forward-modal", Component: IndRegisterForwardModal },
];

export const jobSeekerLinks = [
  { to: "/job-seeker/register", label: "Kayıt Ol" },
  { to: "/job-seeker/login", label: "Giriş Yap" },
  { to: "/job-seeker/confirmation-code", label: "Onay Kodu" },
  { to: "/job-seeker/reset-password", label: "Şifre Sıfırla" },
  { to: "/job-seeker/password-renewal", label: "Şifre Yenile" },
  { to: "/job-seeker/profile", label: "Kullanıcı Profili" },
  { to: "/job-seeker/job-posting", label: "İş İlanları" },
  { to: "/job-seeker/job-application", label: "İş Başvurusu" },
];

export const employerLinks = [
  { to: "/employer/register", label: "Kayıt Ol" },
  { to: "/employer/login", label: "Giriş Yap" },
  { to: "/employer/confirmation-code", label: "Onay Kodu" },
  { to: "/employer/reset-password", label: "Şifre Sıfırla" },
  { to: "/employer/password-renewal", label: "Şifre Yenile" },
  { to: "/employer/company-info", label: "Firma Bilgileri" },
];

export const jobListings = [
  {
    id: 1,
    company: "Burger King",
    title: "Şef Garson",
    description:
      "Mağazamıza tecrübeli, askerliğini yapmış, 30 yaş üzeri, ehliyeti olan şef garson aranmaktadır.",
  },
  {
    id: 2,
    company: "Burger King",
    title: "Şef Garson",
    description:
      "Mağazamıza tecrübeli, askerliğini yapmış, 30 yaş üzeri, ehliyeti olan şef garson aranmaktadır.",
  },
];

export const sortOptions = [
  { value: "high_scores", label: "Yüksek Puanlar" },
  { value: "most_reviewed", label: "Çok Değerlendirilenler" },
  { value: "salary_high_to_low", label: "Ücret(Yüksekten Düşüğe)" },
  { value: "salary_low_to_high", label: "Ücret(Düşükten Yükseğe)" },
  { value: "age_high_to_low", label: "Yaş(Yüksekten Düşüğe)" },
  { value: "age_low_to_high", label: "Yaş(Düşükten Yükseğe)" },
];

export const RoleOptions = {
  EDITOR: "Editör",
  OWNER: "Şirket Sahibi",
  MEMBER: "Üye",
  MANAGER: "Yönetici",
};
