import React, { useState, useEffect } from "react";
import { fetchDashboardData } from "@/api/company";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { JOB_STATUS } from "@/libs/constants";

// API'den dönen verilerin tür tanımlamaları
interface Job {
  id: number;
  title: string;
  status: string;
  company: {
    name: string;
  };
  province: {
    name: string;
  };
  district: {
    name: string;
  };
}

interface Application {
  id: number;
  job: Job;
  applicant: any;
}

interface DashboardData {
  total_active_jobs: number;
  total_applications: number;
  total_reviews: number;
  last_job_applications: Application[];
  last_jobs: Job[];
}

const Dashboard = () => {
  const firstName = useSelector((state: RootState) => state.user.first_Name);
  const lastName = useSelector((state: RootState) => state.user.last_Name);
  // Dinamik state'ler
  const [data, setData] = useState<DashboardData>({
    total_active_jobs: 0,
    total_applications: 0,
    total_reviews: 0,
    last_job_applications: [],
    last_jobs: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null); // string | null olarak set ediyoruz

  // API'den veri çekmek için useEffect kullanıyoruz
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDashboardData();
        setData(response.data); // API'den gelen 'data' kısmını state'e set ediyoruz
        setLoading(false); // Veri yüklendiğinde loading'i false yap
      } catch (err) {
        setError("Veriler çekilirken hata oluştu."); // Error tipi string olarak ayarlandı
        setLoading(false); // Hata alındığında da loading'i durdur
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Yükleniyor...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div className="row" style={{ boxSizing: "border-box" }}>
        <div className="col-md-12">
          <div className="dashboard-box margin-top-0">
            <div
              className="headline"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3>
                <i className="icon-material-outline-face"></i>{" "}
                {firstName && lastName ? (
                  <>
                    <span className="welcome-t">Hoşgeldiniz, </span>
                    <span className="welcome-t">
                      {firstName} {lastName}
                    </span>
                  </>
                ) : (
                  <span>Kullanıcı Bilgisi Yok</span>
                )}
              </h3>
            </div>

            {/* Fun Facts Container */}
            <div className="col-md-12 dashboard-box ">
              <div className="fun-facts-container margin-top-20 padding-top-10">
                <div
                  className="fun-fact margin-top-15"
                  data-fun-fact-color="#36bd78"
                >
                  <div className="fun-fact-text">
                    <span>Beklenen bakiye</span>
                    <h4>0 TL</h4>
                  </div>
                  <div className="fun-fact-icon">
                    <i
                      className="icon-material-outline-gavel"
                      style={{ color: "rgb(54, 189, 120)" }}
                    ></i>
                  </div>
                </div>
                <div
                  className="fun-fact margin-top-15"
                  data-fun-fact-color="#36bd78"
                >
                  <div className="fun-fact-text">
                    <span>Bu ayki harcamanız</span>
                    <h4>0 TL</h4>
                  </div>
                  <div className="fun-fact-icon">
                    <i
                      className="icon-material-outline-gavel"
                      style={{ color: "rgb(54, 189, 120)" }}
                    ></i>
                  </div>
                </div>

                <div
                  className="fun-fact margin-top-15"
                  data-fun-fact-color="#36bd78"
                >
                  <div className="fun-fact-text">
                    <span>Aktif İş Sayısı</span>
                    <h4>{data.total_active_jobs}</h4>
                  </div>
                  <div className="fun-fact-icon">
                    <i
                      className="icon-material-outline-gavel"
                      style={{ color: "rgb(54, 189, 120)" }}
                    ></i>
                  </div>
                </div>
                <div
                  className="fun-fact margin-top-15"
                  data-fun-fact-color="#b81b7f"
                >
                  <div className="fun-fact-text">
                    <span>Toplam Müracaat </span>
                    <h4>{data.total_applications}</h4>
                  </div>
                  <div className="fun-fact-icon">
                    <i
                      className="icon-material-outline-business-center"
                      style={{ color: "rgb(184, 27, 127)" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            {/* Son 5 Başvuru */}
            <div className="row margin-top-30">
              <div className="col-md-12">
                <div className="dashboard-box">
                  <div
                    className="headline"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h3>
                      <i className="icon-material-baseline-notifications-none"></i>{" "}
                      Son 5 Başvuru
                    </h3>
                    
                  </div>
                  <div className="content">
                    <ul className="dashboard-box-list">
                      {data.last_job_applications.length > 0 ? (
                        data.last_job_applications.map(
                          (application: Application, index) => (
                            <li key={index}>
                              <span className="notification-icon">
                                <i className="icon-material-outline-group"></i>
                              </span>
                              <span className="notification-text">
                                <strong>
                                  {application.job.title} -{" "}
                                  {application.applicant.first_name}{" "}
                                  {application.applicant.last_name}
                                </strong>{" "}
                                iş başvurusunda bulundu.
                              </span>
                              <div className="buttons-to-right">
                                <Link
                                  to="/employer/company-info/job-applications"
                                  className="button ripple-effect ico"
                                  title="Mark as read"
                                  data-tippy-placement="left"
                                >
                                  <i className="icon-feather-check-square"></i>
                                </Link>
                              </div>
                            </li>
                          )
                        )
                      ) : (
                        <li>
                          <span className="notification-text">
                            Henüz başvuru yapılmadı.
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              {/* Aktif İlanlar */}
              <div className="col-md-12">
                <div className="dashboard-box">
                  <div
                    className="headline"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h3>
                      <i className="icon-material-outline-assignment"></i> Aktif
                      İlanlar
                    </h3>
                   
                  </div>
                  <div className="content">
                    <ul className="dashboard-box-list">
                      {data.last_jobs.length > 0 ? (
                        data.last_jobs.map((job: Job, index) => (
                          <li key={index}>
                            <div className="invoice-list-item">
                              <strong>{job.title}</strong>
                              <ul>
                                <li>
                                  <span>
                                    {
                                      JOB_STATUS[
                                        job.status as keyof typeof JOB_STATUS
                                      ]
                                    }
                                  </span>
                                </li>
                                <li>{job.company.name}</li>
                                <li>{job.province.name}</li>
                                <li>{job.district.name}</li>
                              </ul>
                            </div>
                            <div className="buttons-to-right">
                              <Link
                                to="/employer/company-info/activeJobAds"
                                className="button gray"
                              >
                                Görüntüle
                              </Link>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>
                          <span className="notification-text">
                            Henüz aktif ilan bulunmuyor.
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
