import React from "react";
import { useRef, useState, useEffect } from "react";
import {
  fetchCompanyAuthDocs,
  fetchUploadCompanyDocument,
  fetchDeleteCompanyDocument,
} from "@/api/company";

// const CompanyAuth = () => {

//   const fileInputRef = useRef(null);

//   const handleButtonClick = () => {
//     // `fileInputRef` öğesini tıklatarak dosya seçimi açılır
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (event) => {
//     // Dosya seçildiğinde çağrılır
//     const selectedFile = event.target.files[0];
//     if (selectedFile) {
//       console.log("Seçilen dosya:", selectedFile.name);
//     }

//   return (
//     <>
//       <div className="col-md-12 dashboard-box margin-top-30">
//         <div className="headline">
//           <h3>
//             <i className="icon-line-awesome-comment-o"></i> Yetki
//           </h3>
//           <p className="authority-text">
//             Tüm bilgileri tamamla, onaylı firma ol ve iş gücüne ulaş
//           </p>
//         </div>
//         <div className="content with-padding padding-bottom-0">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="submit-field">
//                 <div className="margin-top-8">

{
  /* <input
                    className="uploadButton-input"
                    type="file"
                    accept="image/*, application/pdf"
                    id="upload"
                    multiple={true}
                  /> */
}

{
  /* <label
                    className="uploadButton-button ripple-effect"
                    htmlFor="upload"
                  >
                    Yetki Belgesi Ekle
                  </label>
                  <span className="uploadButton-file-name">
                    Yetki belgenizi yükleyin
                  </span> */
}

{
  /* <a className="uploadButton-file-name">
                    <i className="uploadButton-button ripple-effect "> </i>{" "}
                    Yetki Belgenizi Yükleyiniz !
                  </a> */
}

{
  /* <div className="row">
                    <div className="col-md-3 margin-bottom-10">
                      <a className="button ripple-effect small">
                        <i className="icon-feather-plus"></i> Belge Yükle
                      </a>
                    </div>
                  </div>
                </div> */
}

// <div className="row">
//       <div className="col-md-3 margin-bottom-10">
//         {/* Görünmez dosya girişi */}
//         <input
//           type="file"
//           ref={fileInputRef}
//           style={{ display: "none" }}
//           onChange={handleFileChange}
//         />
//         {/* Buton */}
//         <a
//           className="button ripple-effect small"
//           onClick={handleButtonClick}
//           style={{ cursor: "pointer" }}
//         >
//           <i className="icon-feather-plus"></i> Belge Yükle
//         </a>
//       </div>
//     </div>

//                 <div className="row">
//                   <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
//                     <button
//                       className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                       type="submit"
//                       form="login-form"
//                     >
//                       Bilgileri Güncelle{" "}
//                       <i className="icon-material-outline-arrow-right-alt"></i>
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               <br />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CompanyAuth;

// API çağrısı yapılan fonksiyon

// const CompanyAuth = () => {
//   const fileInputRef = useRef<any>(null);

//   // Backend'den gelen dosyaları saklamak için state
//   const [documents, setDocuments] = useState<{ name: string; url: string }[]>(
//     []
//   );

//   // Backend'den veri çekmek için useEffect
//   useEffect(() => {
//     const fetchDocuments = async () => {
//       try {
//         const response = await fetchCompanyAuthDocs(); // API çağrısı
//         if (response?.data?.status === "0") {
//           setDocuments(response.data.documents); // Gelen dosyaları state'e kaydet
//         } else {
//           console.error("API Hatası:", response?.data?.message);
//         }
//       } catch (error) {
//         console.error("API İsteği Başarısız:", error);
//       }
//     };

//     fetchDocuments();
//   }, []);

//   const handleButtonClick = () => {
//     if (fileInputRef.current) {
//       fileInputRef.current.click();
//     }
//   };

//   const handleFileChange = (event: any) => {
//     const selectedFile = event.target.files[0];
//     if (selectedFile) {
//       console.log("Seçilen dosya:", selectedFile.name);
//     }
//   };

//   return (
//     <>
//       <div className="col-md-12 dashboard-box margin-top-30">
//         <div className="headline">
//           <h3>
//             <i className="icon-line-awesome-comment-o"></i> Yetki
//           </h3>
//           <p className="authority-text">
//             Tüm bilgileri tamamla, onaylı firma ol ve iş gücüne ulaş
//           </p>
//         </div>
//         <div className="content with-padding padding-bottom-0">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="submit-field">
//                 <div className="margin-top-8">
//                   <div className="row">
//                     <div className="col-md-3 margin-bottom-10">
//                       <input
//                         type="file"
//                         ref={fileInputRef}
//                         style={{ display: "none" }}
//                         onChange={handleFileChange}
//                       />
//                       <a
//                         className="button ripple-effect small"
//                         onClick={handleButtonClick}
//                         style={{ cursor: "pointer" }}
//                       >
//                         <i className="icon-feather-plus"></i> Belge Yükle
//                       </a>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Sisteme Yüklenmiş Dosyalar */}
//                 <div className="uploaded-documents margin-top-20">
//                   <h4>Yüklenmiş Dosyalar:</h4>
//                   <ul>
//                     {documents.map((doc: any, index: any) => (
//                       <li key={index}>
//                         <a
//                           href={doc.url}
//                           target="_blank"
//                           rel="noopener noreferrer"
//                         >
//                           {doc.name}
//                         </a>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>

//                 <div className="row">
//                   <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
//                     <button
//                       className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                       type="submit"
//                     >
//                       Bilgileri Güncelle{" "}
//                       <i className="icon-material-outline-arrow-right-alt"></i>
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               <br />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CompanyAuth;

// const CompanyAuth = () => {
//   const fileInputRef = useRef<any>(null);

//   const [documents, setDocuments] = useState<{ name: string; url: string }[]>(
//     []
//   );

//   const [selectedFile, setSelectedFile] = useState<File | null>(null);

//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const fetchDocuments = async () => {
//       try {
//         const response = await fetchCompanyAuthDocs(); // API çağrısı
//         if (response?.data?.status === "0") {
//           setDocuments(response.data.documents); // Gelen dosyaları state'e kaydet
//         } else {
//           console.error("API Hatası:", response?.data?.message);
//         }
//       } catch (error) {
//         console.error("API İsteği Başarısız:", error);
//       }
//     };

//     fetchDocuments();
//   }, []);

//   const uploadDocument = async () => {
//     if (!selectedFile) {
//       alert("Lütfen bir dosya seçin!");
//       return;
//     }

//     try {
//       setLoading(true);

//       const reader = new FileReader();
//       const base64File = await new Promise<string>((resolve, reject) => {
//         reader.onload = () => {
//           if (reader.result) {
//             resolve(reader.result.toString().split(",")[1]);
//         };
//         reader.onerror = () => reject("Dosya base64'e dönüştürülemedi");
//         reader.readAsDataURL(selectedFile);
//       });

//       const response = await fetchUploadCompanyDocument(
//         selectedFile.name,
//         selectedFile.type,
//         base64File
//       );

//       if (response?.data?.status === "0") {
//         alert("Belge başarıyla yüklendi!");

//         setDocuments((prev) => [
//           ...prev,
//           { name: selectedFile.name, url: response.data.url },
//         ]);
//       } else {
//         alert(`Belge yüklenemedi: ${response?.data?.message}`);
//       }
//     } catch (error) {
//       console.error("Belge yükleme hatası:", error);
//       alert("Belge yüklenirken bir hata oluştu.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleButtonClick = () => {
//     if (fileInputRef.current) {
//       fileInputRef.current.click();
//     }
//   };

//   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       setSelectedFile(file);
//     }
//   };

//   const handleUpdate = async () => {
//     await uploadDocument();
//   };

//   return (
//     <>
//       <div className="col-md-12 dashboard-box margin-top-30">
//         <div className="headline">
//           <h3>
//             <i className="icon-line-awesome-comment-o"></i> Yetki
//           </h3>
//           <p className="authority-text">
//             Tüm bilgileri tamamla, onaylı firma ol ve iş gücüne ulaş
//           </p>
//         </div>
//         <div className="content with-padding padding-bottom-0">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="submit-field">
//                 <div className="margin-top-8">
//                   <div className="row">
//                     <div className="col-md-3 margin-bottom-10">
//                       <input
//                         type="file"
//                         ref={fileInputRef}
//                         style={{ display: "none" }}
//                         onChange={handleFileChange}
//                       />
//                       <a
//                         className="button ripple-effect small"
//                         onClick={handleButtonClick}
//                         style={{ cursor: "pointer" }}
//                       >
//                         {selectedFile ? selectedFile.name : "Belge Yükle"}
//                       </a>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="uploaded-documents margin-top-20">
//                   <h4>Yüklenmiş Dosyalar:</h4>
//                   <ul>
//                     {documents.map((doc, index) => (
//                       <li key={index}>
//                         <a
//                           href={doc.url}
//                           target="_blank"
//                           rel="noopener noreferrer"
//                         >
//                           {doc.name}
//                         </a>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>

//                 <div className="row">
//                   <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
//                     <button
//                       className="button full-width button-sliding-icon ripple-effect margin-top-30"
//                       type="button"
//                       onClick={handleUpdate}
//                       disabled={loading}
//                     >
//                       {loading ? "Yükleniyor..." : "Bilgileri Güncelle"}{" "}
//                       <i className="icon-material-outline-arrow-right-alt"></i>
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               <br />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CompanyAuth;

const CompanyAuth = () => {
  const fileInputRef = useRef<any>(null);

  // Backend'den gelen dosyaları saklamak için state
  const [documents, setDocuments] = useState<
    { id: number; name: string; url: string }[]
  >([]);

  // Yüklenecek dosyayı saklamak için state
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // Dosya yükleme işlemi için loading durumu
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetchCompanyAuthDocs();
        if (response?.data?.status === "0") {
          setDocuments(response.data.documents);
        } else {
          console.error("API Hatası:", response?.data?.message);
        }
      } catch (error) {
        console.error("API İsteği Başarısız:", error);
      }
    };

    fetchDocuments();
  }, []);

  const uploadDocument = async () => {
    if (!selectedFile) {
      alert("Lütfen bir dosya seçin!");
      return;
    }

    try {
      setLoading(true);

      const reader = new FileReader();
      const base64File = await new Promise<string>((resolve, reject) => {
        reader.onload = () => {
          if (reader.result) {
            resolve(reader.result.toString().split(",")[1]);
          }
        };
        reader.onerror = () => reject("Dosya base64'e dönüştürülemedi");
        reader.readAsDataURL(selectedFile);
      });

      const response = await fetchUploadCompanyDocument(
        selectedFile.name,
        selectedFile.type,
        base64File
      );

      if (response?.data?.status === "0") {
        alert("Belge başarıyla yüklendi!");
        setDocuments((prev) => [
          ...prev,
          {
            id: response.data.id,
            name: selectedFile.name,
            url: response.data.url,
          },
        ]);
      } else {
        alert(`Belge yüklenemedi: ${response?.data?.message}`);
      }
    } catch (error) {
      console.error("Belge yükleme hatası:", error);
      alert("Belge yüklenirken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDelete = async (document_id: number) => {
    const confirmDelete = window.confirm(
      `Belgeyi silmek istediğinize emin misiniz?`
    );

    if (!confirmDelete) return;

    try {
      await fetchDeleteCompanyDocument(document_id); // Silme API çağrısı
      setDocuments((prev) => prev.filter((doc) => doc.id !== document_id)); // State güncelle
      alert(`Belge başarıyla silindi.`);
    } catch (error) {
      console.error("Belge silme hatası:", error);
      alert("Belge silinirken bir hata oluştu.");
    }
  };

  const handleUpdate = async () => {
    await uploadDocument();
  };

  return (
    <>
      <div className="col-md-12 dashboard-box margin-top-30">
        <div className="headline">
          <h3>
            <i className="icon-line-awesome-comment-o"></i> Yetki
          </h3>
          <p className="authority-text">
            Tüm bilgileri tamamla, onaylı firma ol ve iş gücüne ulaş
          </p>
        </div>
        <div className="content with-padding padding-bottom-0">
          <div className="row">
            <div className="col-md-12">
              <div className="submit-field">
                <div className="margin-top-8">
                  <div className="row">
                    <div className="col-md-3 margin-bottom-10">
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <a
                        className="button ripple-effect small"
                        onClick={handleButtonClick}
                        style={{ cursor: "pointer" }}
                      >
                        {selectedFile ? selectedFile.name : "Belge Yükle"}
                      </a>
                    </div>
                  </div>
                </div>

                {/* Sisteme Yüklenmiş Dosyalar */}
                <div className="uploaded-documents margin-top-20">
                  <h4>Yüklenmiş Dosyalar:</h4>
                  <ul>
                    {documents.map((doc) => (
                      <li
                        key={doc.id}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <a
                          href={doc.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginRight: "10px" }}
                        >
                          {doc.name}
                        </a>
                        <button
                          className="icon-line-awesome-trash"
                          onClick={() => handleDelete(doc.id)}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            color: "red",
                          }}
                        ></button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                    <button
                      className="button full-width button-sliding-icon ripple-effect margin-top-30"
                      type="button"
                      onClick={handleUpdate}
                      disabled={loading}
                    >
                      {loading ? "Yükleniyor..." : "Bilgileri Güncelle"}{" "}
                      <i className="icon-material-outline-arrow-right-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyAuth;
