// import React, { useState } from "react";
// import trFlag from "@/images/flags/tr.svg";
// import { useModal } from "@/contexts/ModalContext";
// import noAvatar from "@/images/no-avatar.png";
// import { fetchJobjobApplications } from "@/api/company";
// import { useEffect } from "react";

// const JobApplications = () => {
//   const { showModal } = useModal();
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedValue, setSelectedValue] = useState("Yaşa Göre");

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleOptionClick = (value: string) => {
//     setSelectedValue(value);
//     setIsOpen(false); // Optionally close the dropdown when an option is selected
//   };

//   return (
//     <>
//       <div className="dashboard-headline">
//         <h3>İş Başvuruları</h3>
//         <span className="margin-top-7">
//           Tüm başvuruları buradan görüntüleyebilirsiniz
//         </span>
//       </div>
//       <div className="row">
//         {/* Dashboard Box */}
//         <div className="col-xl-12">
//           <div className="dashboard-box margin-top-0">
//             {/* Headline */}
//             <div className="headline">
//               <h3>
//                 <i className="icon-material-outline-supervisor-account"></i> 3
//                 İş Başvurusu
//               </h3>
//             </div>

//             <div className="content">
//               <ul className="dashboard-box-list">
//                 {/* Job Application Item */}
//                 {Array(3)
//                   .fill("")
//                   .map((_, index) => (
//                     <li key={index}>
//                       <div className="freelancer-overview manage-candidates">
//                         <div className="freelancer-overview-inner">
//                           {/* Avatar */}
//                           <div className="freelancer-avatar">
//                             <div className="verified-badge"></div>
//                             <a href="#">
//                               <img src={noAvatar} alt="" />
//                             </a>
//                           </div>

//                           {/* Name */}
//                           <div className="freelancer-name">
//                             <h4>
//                               <a href="#">
//                                 Ali Limon{" "}
//                                 <img
//                                   className="flag"
//                                   src={trFlag}
//                                   alt=""
//                                   title="Türkiye"
//                                   data-tippy-placement="top"
//                                 />
//                               </a>{" "}
//                               <span className="freelancer-detail-city">
//                                 İstanbul
//                               </span>
//                             </h4>

//                             {/* Details */}
//                             <span className="freelancer-detail-item">
//                               <a href="#">
//                                 <i className="icon-feather-mail"></i>{" "}
//                                 alilimon@gmail.com
//                               </a>
//                             </span>
//                             <span className="freelancer-detail-item">
//                               <i className="icon-feather-phone"></i> 0(544)
//                               123-456-78
//                             </span>

//                             {/* Rating */}
//                             <div className="freelancer-rating">
//                               <div
//                                 className="star-rating"
//                                 data-rating="5.0"
//                               ></div>
//                             </div>

//                             {/* Bid Details */}
//                             <ul className="dashboard-task-info bid-info">
//                               <li>
//                                 <strong>30.000₺</strong>
//                                 <span>Ücret</span>
//                               </li>
//                               <li>
//                                 <strong>Hemen</strong>
//                                 <span>Başlama Zamanı</span>
//                               </li>
//                             </ul>

//                             {/* Buttons */}
//                             <div className="buttons-to-right always-visible margin-top-25 margin-bottom-0">
//                               <a
//                                 onClick={() => showModal("acceptance-modal")}
//                                 className="popup-with-zoom-anim button ripple-effect text-white"
//                               >
//                                 <i className="icon-material-outline-check"></i>{" "}
//                                 Başvuruyu Kabul Et
//                               </a>
//                               <a
//                                 onClick={() => showModal("message-modal")}
//                                 className="popup-with-zoom-anim button dark ripple-effect text-white"
//                               >
//                                 <i className="icon-feather-mail"></i> Mesaj
//                                 Gönder
//                               </a>
//                               <a
//                                 href="#"
//                                 className="button red ripple-effect ico"
//                                 title="Başvuruyu Sil"
//                                 data-tippy-placement="top"
//                               >
//                                 <i className="icon-feather-trash-2"></i>
//                               </a>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </li>
//                   ))}
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default JobApplications;

import React, { useState, useEffect } from "react";
import { useModal } from "@/contexts/ModalContext";
import noAvatar from "@/images/no-avatar.png";
import { fetchJobjobApplications } from "@/api/company"; // API isteği
import { JOB_APPLICATION_STATUS } from "@/libs/constants";

interface Job {
  id: number;
  title: string;
  salary: number;
  province: { name: string };
  district: { name: string };
  company: { name: string };
  business_area: { name: string };
}

interface Applicant {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar: { url: string } | null;
}

interface JobApplication {
  id: number;
  job: Job;
  applicant: Applicant;
  status: string;
  message: string;
  salary: number;
}

interface JobApplicationResponse {
  data: JobApplication[];
  status: string;
}

const JobApplications = () => {
  const { showModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Yaşa Göre");
  const [applications, setApplications] = useState<JobApplication[]>([]); // Başvuru verileri için state
  const [loading, setLoading] = useState(true); // Yükleniyor durumu
  const [error, setError] = useState(""); // Hata durumu için state

  // Dropdown açma/kapatma
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false); // Seçim sonrası dropdown kapanır
  };

  // API'den başvuruları almak için useEffect
  useEffect(() => {
    const handleJobApplications = async () => {
      try {
        const response = (await fetchJobjobApplications()) as any; // API'den başvuruları çek
        console.log("api fucntion has been triggered");

        if (response) {
          setApplications(response); // JobApplication[] dizisini state'e kaydediyoruz
        } else {
          setError("Veriler getirilirken bir sorun oluştu.");
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(`API isteğinde bir hata oluştu: ${error.message}`);
        } else {
          setError("Bilinmeyen bir hata oluştu.");
        }
      } finally {
        setLoading(false); // Yüklenme işlemi tamamlandı
      }
    };

    handleJobApplications();
  }, []);

  // Ekranda gösterilecek içerik (loading, error veya liste)
  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div>Hata: {error}</div>;
  }

  return (
    <>
      <div className="dashboard-headline">
        <h3>İş Başvuruları</h3>
        <span className="margin-top-7">
          Tüm başvuruları buradan görüntüleyebilirsiniz
        </span>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="dashboard-box margin-top-0">
            <div className="headline">
              <h3>
                <i className="icon-material-outline-supervisor-account"></i>{" "}
                {applications.length > 0
                  ? `${applications.length} İş Başvurusu`
                  : "Henüz iş başvurusu bulunmamaktadır."}
              </h3>
            </div>

            <div className="content">
              <ul className="dashboard-box-list">
                {/* Job Application Item */}
                {applications.map((application, index) => (
                  <li key={index}>
                    <div className="freelancer-overview manage-candidates">
                      <div className="freelancer-overview-inner">
                        {/* Avatar */}
                        <div className="freelancer-avatar">
                          <div className="verified-badge"></div>
                          <a href="#">
                            <img
                              src={
                                application.applicant.avatar?.url || noAvatar
                              }
                              alt="Avatar"
                            />
                          </a>
                        </div>

                        {/* Name */}
                        <div className="freelancer-name">
                          <h4>
                            <a href="#">
                              {application.applicant.first_name}{" "}
                              {application.applicant.last_name}
                            </a>
                            <span className="freelancer-detail-city">
                              {application.job.province.name},{" "}
                              {application.job.district.name}
                            </span>
                          </h4>

                          {/* Details */}
                          <span className="freelancer-detail-item">
                            <a href="#">
                              <i className="icon-feather-mail"></i>{" "}
                              {application.applicant.email}
                            </a>
                          </span>
                          <span className="freelancer-detail-item">
                            <i className="icon-feather-phone"></i>{" "}
                            {application.applicant.phone}
                          </span>

                          {/* Job Details */}
                          <ul className="dashboard-task-info bid-info">
                            <li>
                              <strong>{application.job.title}</strong>
                              <span>{application.job.business_area.name}</span>
                            </li>
                            <li>
                              <strong>{application.salary}₺</strong>
                              <span>Ücret/Saat</span>
                            </li>
                            <li>
                              <strong>{JOB_APPLICATION_STATUS[application.status as keyof typeof JOB_APPLICATION_STATUS]}</strong>
                              <span>Başvuru Durumu</span>
                            </li>
                          </ul>

                          {/* Message */}
                          <div className="freelancer-name">
                            <p>Başvuru Mesajı: {application.message}</p>
                          </div>

                          {/* Buttons */}
                          <div className="buttons-to-right always-visible margin-top-25 margin-bottom-0">
                            <a
                              onClick={() => showModal("acceptance-modal",
                              {
                                id: application.id, // Başvuru id'si
                                salary: application.salary, // Ücret
                                jobId: application.job.id, // İş id'si
                                userId: application.applicant.id, // Kullanıcı id'si
                                jobTitle: application.job.title, // İş başlığı
                                userName: `${application.applicant.first_name} ${application.applicant.last_name}`, // Kullanıcı adı ve soyadı
                              })}
                              className="popup-with-zoom-anim button ripple-effect text-white"
                            >
                              <i className="icon-material-outline-check"></i>{" "}
                              Başvuruyu Kabul Et
                            </a>
                            <a
                              onClick={() => showModal("message-modal", {
                                user_id: application.applicant.id, // userId yerine user_id olarak gönderiyoruz
                                userName: `${application.applicant.first_name} ${application.applicant.last_name}`, // Kullanıcı adı ve soyadı
                              })}
                              className="popup-with-zoom-anim button dark ripple-effect text-white"
                            >
                              <i className="icon-feather-mail"></i> Mesaj Gönder
                            </a>
                            <a
                              href="#"
                              className="button red ripple-effect ico"
                              title="Başvuruyu Sil"
                              data-tippy-placement="top"
                            >
                              <i className="icon-feather-trash-2"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobApplications;
