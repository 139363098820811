import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup"; // For validation schema
import Dropdown from "@/components/Dropdown";
import Textarea from "@/components/TextArea";
import profileImg from "@/images/user-avatar-placeholder.png";
import loopArrowImg from "@/images/icons/loop-arrow.png";
import { toast } from "react-toastify";
import { fetchBusinesAreaOptions, fetchCompanyBusinesAreaOptions, fetchProvince } from "@/api/public";
import { fetchCompanyInfo, updateCompanyInfo } from "@/api/company";
import { BarLoader } from "react-spinners";
type Area = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  icon: string | null;
};

interface BusinessArea {
  name: string;
  icon: string;
  id?: string;
}

interface CompanyInfoData {
  name: string;
  email: string;
  description: string;
  rating: number;
  business_area: BusinessArea;
  phone: string;
  website: string;
  logo_name?: string;
  logo_mediatype?: string;
  logo_payload?: string;
}

const CompanyInformation = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subjectActivityOptions, setSubjectActivityOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [initialValues, setInitialValues] = useState<CompanyInfoData>({
    name: "",
    email: "",
    description: "",
    rating: 0,
    business_area: { name: "", icon: "" },
    phone: "",
    website: "",
    logo_name: "",
    logo_mediatype: "",
    logo_payload: "",
  });
  const [profileImage, setProfileImage] = useState<string>("");
  const [accountInfo, setAccountInfo] = useState<any>({
  });
  

  // Fetch the options from the backend
  useEffect(() => {
    const handleFetchBusinessAreaOptions = async () => {
      try {
        const response = await fetchCompanyBusinesAreaOptions(); // Properly fetch the business area options
        const result = response.data;
        if (result && result.areas) {
          const formattedOptions = result.areas.map((area: Area) => ({
            value: area.id.toString(), // Convert number to string
            label: area.name,
          }));
          setSubjectActivityOptions(formattedOptions);
        } else {
          console.error("Unexpected response format", result);
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    const fetchCompanyInfoData = async () => {
      const data = await fetchCompanyInfo();
      if (data) {
        setInitialValues(data); // Set initial values for Formik form
      } else {
        console.error("Failed to fetch company information.");
      }
    };

    Promise.all([handleFetchBusinessAreaOptions(), fetchCompanyInfoData()])
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error during data fetching:", error);
        setIsLoading(false); // Hata durumunda bile loading'i kapatın
      });
  }, []);

  // Formik setup
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true, // Reinitialize form when initialValues change
    validationSchema: Yup.object({
      name: Yup.string().required("Şirket ünvanı gerekli"),
      email: Yup.string()
        .email("Geçersiz e-posta adresi")
        .required("E-posta gerekli"),
      phone: Yup.string().required("Telefon numarası gerekli"),
      website: Yup.string().url("Geçersiz URL").required("Web sitesi gerekli"),
      description: Yup.string().required("Şirket tanıtım metni gerekli"),
    }),
    onSubmit: (values) => {
      console.log("Form Values:", values); // Perform your API submission here
      const { name, email, phone, website, description, business_area } =
        values;
      // Perform your API submission here
      updateCompanyInfo({
        name,
        email,
        phone,
        website,
        description,
        business_area_id: business_area,
        logo_name: values.logo_name,
        logo_media_type: values.logo_mediatype,
        logo_payload: values.logo_payload,
      });
    },
  });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        const name = file.name;
        const type = file.type;
        formik.setFieldValue("logo_name", name);
        formik.setFieldValue("logo_mediatype", type);
        formik.setFieldValue("logo_payload", base64);

        setProfileImage(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  return isLoading ? (
    <BarLoader color="#f7c08a" loading={true} width="100%" height={4} />
  ) : (
    
    <>
      <div className="col-md-12 dashboard-box margin-top-0">
        <div className="headline">
          <h3>
            <i className="icon-material-outline-business-center"></i> Künye
          </h3>
        </div>
        <div className="content with-padding padding-bottom-0">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Şirket Ünvanı</h5>
                      <div
                        className="input-with-icon-left"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="Şirket Ünvanınızı Girin"
                      >
                        <i className="icon-material-outline-business"></i>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Şirket Ünvanı"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.name && formik.errors.name
                              ? "input-error"
                              : ""
                          }
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="error text-sm text-danger">
                            {formik.errors.name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <div className="section-headline">
                        <h5>Faaliyet Konusu</h5>
                      </div>
                      {subjectActivityOptions.length > 0 && (
                        <Dropdown
                          key={
                            initialValues.business_area?.id || "business_area"
                          }
                          selectedValue={initialValues.business_area?.id || ""}
                          options={subjectActivityOptions}
                          placeholder="Hiçbir şey seçilmedi!"
                          onSelect={(value) => {
                            formik.setFieldValue("business_area", value);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>E-posta Adresiniz</h5>
                      <div
                        className="input-with-icon-left"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="E-posta Adresinizi Girin"
                      >
                        <i className="icon-material-outline-business"></i>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="E-posta Adresiniz"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.email && formik.errors.email
                              ? "input-error"
                              : ""
                          }
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="error text-sm text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Telefon Numaranız</h5>
                      <div
                        className="input-with-icon-left"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="Telefon Numaranızı Girin"
                      >
                        <i className="icon-material-outline-business"></i>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Telefon Numaranız"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.phone && formik.errors.phone
                              ? "input-error"
                              : ""
                          }
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <div className="error text-sm text-danger">
                            {formik.errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Web Siteniz</h5>
                      <div
                        className="input-with-icon-left"
                        data-tippy-placement="bottom"
                        data-tippy=""
                        data-original-title="Web Sitenizi Girin"
                      >
                        <i className="icon-material-outline-business"></i>
                        <input
                          type="text"
                          name="website"
                          id="website"
                          placeholder="Web Siteniz"
                          value={formik.values.website}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.website && formik.errors.website
                              ? "input-error"
                              : ""
                          }
                        />
                        {formik.touched.website && formik.errors.website && (
                          <div className="error text-sm text-danger">
                            {formik.errors.website}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 offset-md-4">
                    <div className="submit-field">
                    <h5>Şirket Logosu</h5>
                      <div className="uploadButton margin-top-8">
                      <div
                            className="avatar-wrapper"
                            data-tippy-placement="bottom"
                          >
                            <img
                              className="profile-pic"
                              src={
                                profileImage ||
                                accountInfo.avatar?.url ||
                                profileImg
                              }
                              alt=""
                            />

                          <button
                          data-v-profile-picture=""
                          className="profile-photo"
                        >
                          <img
                            data-v-76dbb04c=""
                            src={loopArrowImg}
                            alt=""
                            className="pp-edit"
                          />
                        </button>

                            <div className="upload-button">
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                placeholder="click me"
                                className="position-absolute top-0 bottom-0"
                              />
                            </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Şirket Tanıtım Metni</h5>
                      <Textarea
                        rows={8}
                        name="description"
                        placeholder="İş ilanlarında görünecek şirket tanıtım metnini giriniz."
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.description &&
                        formik.errors.description && (
                          <div className="error">
                            {formik.errors.description}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                <button
                  className="button full-width button-sliding-icon ripple-effect margin-top-30"
                  type="submit"
                >
                  Bilgileri Güncelle{" "}
                  <i className="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
        <br />
      </div>
    </>
  );
};

export default CompanyInformation;
