interface MakeAnOfferProps {
  hourlyrate: string;
  jobscount: string;
  rehiredcount: string;
}

const MakeAnOffer = ({
  hourlyrate,
  jobscount,
  rehiredcount,
}: MakeAnOfferProps) => {
  return (
    <>
      <div className="profile-overview">
        <div className="overview-item">
          <strong>{hourlyrate}</strong>
          <span>Saatlik Ücret</span>
        </div>
        <div className="overview-item">
          <strong>{jobscount}</strong>
          <span>İş Sayısı</span>
        </div>
      </div>

      {/* <!-- Button --> */}
      <a
        href="#small-dialog"
        className="apply-now-button popup-with-zoom-anim margin-bottom-50"
      >
        Mesaj Gönder
         <i className="icon-material-outline-arrow-right-alt"></i>
      </a>
    </>
  );
};

export default MakeAnOffer;
