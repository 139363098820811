import { fetchCreditCardList } from '@/api/account';
import { fetchJobDetail, fetchMakeJobPayment } from '@/api/jobs';
import { fetchPlans } from '@/api/public';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

const CheckoutJob = () => {
  const params = useParams() as any;
  const [cards, setCards] = useState<any>();
  const [job, setJob] = useState<any>();
  const [card, setCard] = useState<any>({
    nameOnCard: '',
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('creditCard');

  useEffect(() => {
    const handleLoadJobDetail = async () => {
      const response = await fetchJobDetail(params.id);
      setJob(response.data);
    }

    const handleLoadCreditCardList = async () => {
      const response = await fetchCreditCardList();
      setCards(response.data);
    }

    handleLoadJobDetail();
    handleLoadCreditCardList();
  }, [params.id]);

  const handlePayment = async () => {
    if(selectedPaymentMethod === 'creditCard') {

      if(!card.nameOnCard || !card.cardNumber || !card.expiryMonth || !card.expiryYear || !card.cvv) {
        toast.error('Lütfen tüm alanları doldurunuz');
        return;
      }
      const response = await fetchMakeJobPayment(params.id, card.nameOnCard, card.cardNumber, card.expiryMonth, card.expiryYear, card.cvv, window.location.href, undefined);
      if (response.status === "0") {
        if(response.data.verification_required){
          window.location.href = response.data.url;

        }else
        toast.success("Ödeme başarılı bir şekilde gerçekleştirildi");
      } else {
        toast.error("Ödeme sırasında bir hata oluştu: " + response.message);
      }
      
    }else{
      const cardId = parseInt(selectedPaymentMethod.split('_')[1]);
      const response = await fetchMakeJobPayment(params.id, card.nameOnCard, card.cardNumber, card.expiryMonth, card.expiryYear, card.cvv, window.location.href, cardId);
      if (response.status === "0") {
        if(response.data.verification_required){
          window.location.href = response.data.url;

        }else
        toast.success("Ödeme başarılı bir şekilde gerçekleştirildi");
      } else {
        toast.error("Ödeme sırasında bir hata oluştu: " + response.message);
      }
    }

    
    
  };


  return (
    <>
      <div id="titlebar" className="gradient">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Satın Al</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 content-right-offset">

            

            {/* Hedline */}
            <h3 className="padding-top-5">Ödeme Yöntemi</h3>

            {/* Payment Methods Accordion */}
            <div className="payment margin-top-5">
              <div className="payment-tab payment-tab-active">
              <div className="boxed-widget-headline">
              <h3>Kredi Kartı İle Öde</h3></div>
                <div className="payment-tab-trigger">
                  <input
                    type="radio"
                    name="cardType"
                    id="creditCart"
                    value="creditCard"
                    defaultChecked
                    onChange={() => setSelectedPaymentMethod('creditCard')}
                    checked={selectedPaymentMethod === 'creditCard'}
                  />
                  <label htmlFor="creditCart">Kredi Kartı ile öde</label>
                  <img
                    className="payment-logo"
                    src="https://i.imgur.com/IHEKLgm.png"
                    alt=""
                  />
                </div>

                <div className="payment-tab-content">
                  <div className="row payment-form-row">
                    <div className="col-md-6">
                      <div className="card-label">
                        <input
                          id="nameOnCard"
                          name="nameOnCard"
                          required
                          type="text"
                          placeholder="Kart Sahibinin Adı"
                          value={card.nameOnCard}
                          onChange={(e) =>
                            setCard({ ...card, nameOnCard: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card-label">
                        <input
                          id="cardNumber"
                          name="cardNumber"
                          placeholder="Kredi Kartı Numarası"
                          required
                          type="text"
                          value={card.cardNumber}
                          onChange={(e) =>
                            setCard({ ...card, cardNumber: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card-label">
                        <input
                          id="expiryDate"
                          placeholder="Ay"
                          required
                          type="text"
                          value={card.expiryMonth}
                          onChange={(e) =>
                            setCard({ ...card, expiryMonth: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card-label">
                        <input
                          id="expiryYear"
                          placeholder="Yıl"
                          required
                          type="text"
                          value={card.expiryYear}
                          onChange={(e) =>
                            setCard({ ...card, expiryYear: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card-label">
                        <input
                          id="cvv"
                          required
                          type="text"
                          placeholder="CVV"
                          value={card.cvv}
                          onChange={(e) =>
                            setCard({ ...card, cvv: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          {cards && cards.length > 0 &&  (
              <div className="payment-tab payment-tab-active">
                  <div className='row'>
                    <div className="col-xl-12 col-lg-12 margin-top-0">
                      <div className="boxed-widget summary margin-top-0">
                        <div className="boxed-widget-headline">
                          <h3>Kayıtlı Kredi Kartın İle Öde</h3></div>
                          <div className="boxed-widget-inner">
                            {cards.map((card: any) => (
                              <div className="payment-tab-trigger">
                                <input
                                  type="radio"
                                  name="cardType"
                                  id={card.id}
                                  value="creditCard"
                                  onChange={() => setSelectedPaymentMethod('savedCard_'+card.id)}
                                  checked={selectedPaymentMethod === 'savedCard_'+card.id}
                                />
                                <label htmlFor={card.id}>
                                  <strong>{card.bank}</strong>
                                </label>
                                <img
                                  className="payment-logo"
                                  src="https://i.imgur.com/IHEKLgm.png"
                                  alt=""
                                />
                                <div className="invoice-list-item">
                                  <ul>
                                    <li>
                                      <span className="paid">{card.brand}</span>
                                    </li>
                                    <li>NO: ****-****-****-{card.last_four}</li>
                                  </ul>
                                </div>
                              </div>
                            ))}

                          
                          </div></div>
                    </div>
                  </div>
              </div>
              )}
            </div>
            {/* Payment Methods Accordion / End */}

            <a
              className="button big ripple-effect margin-top-40 margin-bottom-65"
              onClick={handlePayment}
            >
              Ödeme Yap
            </a>
          </div>

          {/* Summary */}
          <div className="col-xl-4 col-lg-4 margin-top-0 margin-bottom-60">
            {/* Summary */}
            <div className="boxed-widget summary margin-top-0">
              <div className="boxed-widget-headline">
                <h3>Ödeme Özeti</h3>
              </div>
              <div className="boxed-widget-inner">
                <ul>
                  <li>
                    Toplam net ücret <span>{job?.price_info?.total_net}₺</span>
                  </li>
                  <li>
                    Toplam maliyet <span>{job?.price_info?.total_cost}₺</span>
                  </li>

                  <li>
                    Vergi <span>{job?.price_info?.total_tax}₺</span>
                  </li>
                  <li className="total-costs">
                    Toplam Fatura Tutarı <span>{job?.price_info?.total_invoice}₺</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* Summary / End */}

            {/* Checkbox */}
            <div className="checkbox margin-top-30">
              <input type="checkbox" id="two-step" />
              <label htmlFor="two-step">
                <span className="checkbox-icon"></span>
                <a href="#">Kullanıcı Sözleşmesini </a> ve{' '}
                <a href="#">Satın Alma Sözleşmesini</a> onaylıyorum.
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckoutJob
