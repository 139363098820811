import axios from 'axios';
import api from './api'; // Adjust the import based on your project structure

export const fetchPublicJobs = async (data:any) => {
    const result = await api.post('/job/public/search', data);
    return result as any;
}

export const fetchJobDetailPublic = async (job_id: string) => {
    const result = await api.get(`/job/public/${job_id}`);
    return result as any;
}

export const fetchJobDetail = async (job_id: string) => {
    const result = await api.get(`/job/${job_id}`);
    return result as any;
}
export const fetchMakeJobPayment = async (job_id: string, owner_name: string, card_number: string, expire_month: string, expire_year: string, cvc: string,return_url:string, card_id?:number) => {
    const result = await api.post(`/job/payment/${job_id}`, { owner_name, card_number, expire_month, expire_year, cvc,return_url, save_card: false, card_id: card_id });
    return result as any;
}

export const fetchMakePlanPayment = async (plan_id: string,selectedPeriod:string, owner_name: string, card_number: string, expire_month: string, expire_year: string, cvc: string,return_url:string, card_id?:number) => {
    const result = await api.post(`/profile/company/payment/${plan_id}`, {period: selectedPeriod,owner_name, card_number, expire_month, expire_year, cvc,return_url, save_card: false, card_id: card_id });
    return result as any;
}
