import React from "react";
import Textarea from "./TextArea";
import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};
const center = {
  lat: 41.009963, // Latitude
  lng: 28.980112, // Longitude
};

const Contact = () => {
  const onLoad = (ref: any) => {
    console.log(ref);
  };
  return (
    <>
      <div className="col-md-12 dashboard-box margin-top-0">
        <div id="titlebar" className="gradient">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>İletişim</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-location-info margin-bottom-50">
                <div className="contact-address">
                  <ul>
                    <li className="contact-address-headline">
                      PartTimeJet Ofis Adresimiz
                    </li>
                    <li>718. Cd. Meta Sitesi No:13 İstanbul</li>
                    <li>Tel: (312) 123-456-789</li>
                    <li>
                      <a href="#">info@parttimejet.com</a>
                    </li>
                    <li className="contact-address-socialline"></li>
                    <li>
                      <div className="freelancer-socials">
                        <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/parttimejet/"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Facebook"
                          >
                            <i className="icon-brand-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/parttimejet"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Twitter"
                          >
                            <i className="icon-brand-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/parttimejet/"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Instagram"
                          >
                            <i className="icon-brand-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/@parttimeJET"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Instagram"
                          >
                            <i className="icon-brand-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/parttimejet"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="LinkedIn"
                          >
                            <i className="icon-brand-linkedin-in"></i>
                          </a>
                        </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <div id="single-job-map-container">
                  {/* Google Map Component */}
                  <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
                      <Marker position={center} />
                    </GoogleMap>
                  </LoadScript>
                  <a href="#" id="streetView">
                    Sokak Görünümü
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2">
              <section id="contact" className="margin-bottom-60">
                <h3 className="headline margin-top-15 margin-bottom-35">
                  Sorunlarınız için bizimle iletişime geçebilirsiniz...
                </h3>

                <form method="post" name="contactform" id="contactform">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-with-icon-left">
                        <input
                          className="with-border"
                          name="name"
                          type="text"
                          id="name"
                          placeholder="İsminiz"
                          required={true}
                        />
                        <i className="icon-material-outline-account-circle"></i>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-with-icon-left">
                        <input
                          className="with-border"
                          name="email"
                          type="email"
                          id="email"
                          placeholder="Email Adresiniz"
                          pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
                          required={true}
                        />
                        <i className="icon-material-outline-email"></i>
                      </div>
                    </div>
                  </div>

                  <div className="input-with-icon-left">
                    <input
                      className="with-border"
                      name="subject"
                      type="text"
                      id="subject"
                      placeholder="Konu"
                      required={true}
                    />
                    <i className="icon-material-outline-assignment"></i>
                  </div>

                  <div>
                    <Textarea />
                  </div>

                  <input
                    type="submit"
                    className="submit button margin-top-15"
                    id="submit"
                    value="Gönder"
                  />
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
