interface FreelancerSkillsProps {
  skills: string[]; // Beceriler bir dizi olarak tanımlandı
}

const FreelancerSkills = ({ skills }: FreelancerSkillsProps) => {
  return (
    <div className="sidebar-widget">
      <h3>Yetenekler</h3>
      <div className="task-tags">
        {skills.map((skill, index) => (
          <span key={index}>{skill}</span>
        ))}
      </div>
    </div>
  );
};

export default FreelancerSkills;
