import Sidebar from "@/components/Sidebar";
import { profileNavSection } from "@/libs/constants";
import { Outlet } from "react-router-dom";

const UserProfile = () => {
  return (
    <div className="dashboard-container" style={{ height: "827px" }}>
      <Sidebar navSections={profileNavSection} />

      <div
        className="dashboard-content-container"
        data-simplebar="init"
        style={{ height: "auto", overflowY: "auto" }}
      >
        <div
          className="simplebar-track vertical"
          style={{ visibility: "visible" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ visibility: "visible", top: "0px", height: "519px" }}
          ></div>
        </div>
        <div
          className="simplebar-track horizontal"
          style={{ visibility: "visible" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ visibility: "visible", left: "0px", width: "25px" }}
          ></div>
        </div>
        <div
          className="simplebar-scroll-content"
          style={{ paddingRight: "20px", marginBottom: "-20px" }}
        >
          <div className="simplebar-content" style={{ paddingBottom: "20px" }}>
            <div
              className="dashboard-content-inner"
              style={{ minHeight: "827px" }}
            >
              <Outlet />

              <div className="dashboard-footer-spacer"></div>
              <div className="small-footer margin-top-15">
                <div className="small-footer-copyrights">
                  © 2024 <strong>ParttimeJET</strong>. Tüm Hakları Saklıdır.
                </div>
                <ul className="footer-social-links">
                <li>
                          <a
                            href="https://www.facebook.com/parttimejet/"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Facebook"
                          >
                            <i className="icon-brand-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/parttimejet"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Twitter"
                          >
                            <i className="icon-brand-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/parttimejet/"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Instagram"
                          >
                            <i className="icon-brand-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/@parttimeJET"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="Instagram"
                          >
                            <i className="icon-brand-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/parttimejet"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy="true"
                            data-original-title="LinkedIn"
                          >
                            <i className="icon-brand-linkedin-in"></i>
                          </a>
                        </li>
                </ul>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
