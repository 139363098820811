import React, {FormEvent, useEffect, useState } from 'react'

import clockImg from '@/images/icons/clock.png'
import useOtp from '@/hooks/useOtp'
import { debug } from 'console'
import { useNavigate } from 'react-router-dom'

interface ConfirmationCodeProps {
  otpLength?: number
  onSubmit?: (otp: string[]) => void
  title?: string
  subtitle?: string
  description?: string
  email?: string
  expirationTime?: number
}

const OtpCode: React.FC<ConfirmationCodeProps> = ({
  otpLength = 6,
  onSubmit,
  title,
  subtitle,
  description,
  email,
  expirationTime
}) => {
  const { otp, activeOtpIndex, inputRef, handleChange, onKeyChange } = useOtp({
    otpLength
  })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (onSubmit) {
      onSubmit(otp)
    }
  }

  const navigate = useNavigate();
  
  const initialTime =Math.floor(expirationTime || 0) - Math.floor(Date.now() / 1000);
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    if(timeRemaining <= 0){
      navigate('/login');
      return
    }

    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          // Perform actions when the timer reaches zero
          console.log('Countdown complete!');
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, []); // The empty dependency array ensures the effect runs only once on mount

  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;



  return (
    <form onSubmit={handleSubmit} className='form-container'>
      <div className="container">
        <div className="row r-container">
          <div className="col-md-8 offset-md-2">
            <div className="reset-password-page">
              <div className="welcome-text">
                {title && <h3 style={{ fontSize: '26px' }}>{title}</h3>}
                {subtitle && <span>{subtitle}</span>}
                {(description || email) && (
                  <span>
                    {description && <>{description}: </>}
                    {email && <strong>{email}</strong>}
                  </span>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-center timer-area timer-active">
                <div>
                  <img src={clockImg} alt="Doğrulama ikonu" />{' '}
                  <span id="counter" className="ml-2">
                    {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center gap-2">
                {otp.map((value: number, index: number) => (
                  <input
                    key={index}
                    ref={activeOtpIndex === index ? inputRef : null}
                    type="number"
                    name={`otp-${index}`}
                    placeholder=""
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => onKeyChange(e, index)}
                    className="confirm-code text-center"
                  />
                ))}
              </div>

              <button
                className="button full-width button-sliding-icon ripple-effect margin-top-20"
                type="submit"
              >
                Doğrula{' '}
                <i className="icon-material-outline-arrow-right-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default OtpCode
