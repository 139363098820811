import api from './api'
export const fetchBankDetails = async() => {
    const result = await api.get('/account/bank-details');
    return result;
}

export const updateBankDetails = async (holder_name: string, iban: string) => {
    const result = await api.post('/account/bank-details', { holder_name, iban });
    return result as any;
}

export const addCard = async (owner_name: string, card_number: string, expire_month: string, expire_year: string, cvc: string, return_url:string) => {
    const result = await api.post('/wallet/card', { owner_name, card_number, expire_month, expire_year, cvc,return_url, save_card: true });
    return result as any;
}

export const deleteCard = async (card_id: number) => {
    const result = await api.delete(`/wallet/card/${card_id}`);
    return result as any;
}

export const fetchAccountInfo = async () => {
    const result = await api.get('/account');
    return result as any;
}

export const fetchColleagueIdentity = async (manager_id: number) => {
    const result = await api.get(`/profile/company/manager/${manager_id}/identity`);
    return result as any;
  };

  
export const updateAccountInfo = async (request: any) => {
    const result = await api.put('/account', request);
    return result as any;
}




export const updateAccountAddress = async (address: string, provinceId: number, districtId: number) => {
 
    const result = await api.post('/account/info/address', {address, province_id:provinceId, district_id:districtId} );

    return result as any;
};

export const ColleagueAddressSubmit = async (
    managerId: number,
    address: string,
    provinceId: number,
    districtId: number,
    
  ) => {
    const result = await api.put(
      `/profile/company/manager/${managerId}/address`,
      { address, province_id: provinceId, district_id: districtId, user_id:0 }
    );
    return result;
  };


export const fetchPersonalAddress = async () => {
    const result = await api.get('/account/info/address');
    return result as any;
}




export const uploadProfileImage = async (name: string, media_type: string, payload: any) => {
    const result = await api.post(`/account/avatar`, { name, media_type, payload });
    return result as any;
}

export const fetchNotifications = async () => {
    const result = await api.get('/notifications');
    return result as any;
}

export const fetchMessagesHistory = async () => {
    const result = await api.get('/messaging/history');
    return result as any;
}

export const fetchMessages = async (group_id: number) => {
    const result = await api.get(`/messaging/history/${group_id}`);
    return result as any;
}

export const sendMessageToGroup = async (group_id: number, message: string) => {
    const result = await api.post(`/messaging/send/${group_id}`, { message });
    return result as any;
}

export const updatePassword = async (old_password: string, new_password: string) => {
    const result = await api.post('/account/password', { current_password:old_password, new_password });
    return result as any;
}

export const deleteMessageGroup = async (group_id: number) => {
    const result = await api.delete(`/messaging/history/${group_id}`);
    return result as any;
}

export const fetchForgotPassword = async (email: string) => {
    const result = await api.post('/auth/forgotten-password', { email, verification_type: 'MAIL' });
    return result as any;
}


export const sendMessageToColleague = async (user_id: number, message: string) =>{
    const result = await api.post(`/messaging/create/user/${user_id}`, {message});
    return result as any;
}


export const fetchCreditCardList = async() =>{
    const response = await api.get('/wallet/card');
    return response as any;
  }

  

  export const fetchAddCreditCard = async (
    owner_name: string,
    card_number: string,
    expire_month: string,
    expire_year: string,
    cvc: string,
    product_id: number = 0, // Default product ID as 0
    save_card: boolean = true // Default save_card as true
  ) => {
    // Prepare the request body
    const payload = {
      owner_name,
      card_number,
      expire_month,
      expire_year,
      cvc,
      product_id, // Pass the product_id as a number
      save_card, // Pass the save_card as a boolean
    };
  
    try {
      // Make the API POST request
      const result = await api.post('/wallet/card', payload);
      return result.data; // Return the response data
    } catch (error) {
      // Handle errors (you can customize error handling here)
      console.error("Error adding credit card:", error);
      throw error; // Optionally rethrow the error to handle it at the caller's end
    }
  };

  

  