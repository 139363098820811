
import axios from 'axios';
import api from './api'

export const fetchProvince = async () => {
    const result = await api.get('/public/province');
    return result as any;
}

export const fetchDistrict = async (province_id: number) => {
    const result = await api.get(`/public/district/${province_id}`);
    return result as any;
}

export const fetchBusinesAreaOptions = async () => {
    const result = await api.get(`/public/business_areas`);
    return result as any;
}
export const fetchCompanyBusinesAreaOptions = async () => {
    const result = await api.get(`/public/company_business_areas`);
    return result as any;
}

export const fetchStatistics = async () => {
    const result = await api.get(`/public/stats`);
    return result as any;
}

export const fetchPlans = async () => {
    const result = await api.get(`/public/plans`);
    return result as any;
}

export const fetchFAQ = async () => {
    const result = await api.get(`/public/faq`);
    return result as any; 
}
export const fetchFreelancer = async (freelancer_id: number) => {
    const result = await api.get(`/profile/personal/public/${freelancer_id}`);
    return result as any;
}