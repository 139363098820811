import { Routes, Route } from "react-router-dom";
import {
  Home,
  UserProfile,
  JobApplication,
  JobPosting,
  Register,
  Login,
  ConfirmationCode,
  ResetPassword,
  PasswordRenewal,
  EmployerRegister,
  EmployerLogin,
  EmployerConfirmationCode,
  EmployerResetPassword,
  EmployerPasswordRenewal,
  CompanyInfo,
  Payment,
  UserInformation,
  MyMessages,
  CorporateInformation,
  CreateJobPosting,
  JobApplications,
  SearchWorkspace,
  SupportCenter,
  Settings,
  MyInformation,
  MyCV,
  UserDocuments,
  MyDocuments,
  MyOrder,
  ActiveJobs,
} from "@/pages";
import RegisterInterfaceComponent from "@/components/RegisterInterfaceComponent";
import FreelancerProfile from "@/pages/jobSeeker/FreelancerProfile/FreelancerProfile";
import ProtectedRoute from "./guarded";
import ForgotPassword from "@/pages/employer/auth/ForgotPassword";
import Contact from "@/components/Contact";
import Colleague from "@/pages/companyInfo/Colleague";
import ColleagueAdd from "@/pages/companyInfo/CorporateInformation/ColleagueAdd";
import Dashboard from "@/pages/companyInfo/Dashboard/Dashboard";
import CheckoutPlan from "@/pages/CheckoutPlan";

import ColleagueEditPage from "@/pages/companyInfo/CorporateInformation/ColleagueEditPage"; // Düzenleme sayfası
import CheckoutJob from "@/pages/CheckoutJob";

const RoutesConfig = () => {
  return (
    <Routes>
      {/* Job Seeker Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<RegisterInterfaceComponent />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/forgot-password/verification"
        element={
          <EmployerConfirmationCode
            key="forgot-mail"
            type="mail"
            action="forgot-password"
          />
        }
      />
      <Route path="/forgot-password/reset" element={<PasswordRenewal />} />
      <Route path="/job-seeker/register" element={<Register />} />
      <Route path="/job-seeker/login" element={<Login />} />
      <Route
        path="/login/verify-email"
        element={
          <EmployerConfirmationCode
            key="login_mail"
            type="mail"
            action="login"
          />
        }
      />
      <Route
        path="/login/verify-phone"
        element={
          <EmployerConfirmationCode
            key="login_phone"
            type="phone"
            action="login"
          />
        }
      />

      <Route path="/job-seeker/reset-password" element={<ResetPassword />} />
      <Route
        path="/job-seeker/password-renewal"
        element={<PasswordRenewal />}
      />
      <Route path="/job-seeker/profile" element={<UserProfile />}>
        <Route
          index
          element={
            <ProtectedRoute>
              <MyInformation />
            </ProtectedRoute>
          }
        />
        <Route
          path="corporate-information"
          element={<CorporateInformation />}
        />
        <Route path="cv" element={<MyCV />} />
        <Route path="documents" element={<MyDocuments />} />
        <Route path="order" element={<MyOrder />} />
        <Route path="job-applications" element={<JobApplications />} />
        <Route path="settings" element={<Settings />} />
        <Route path="support-center" element={<SupportCenter />} />
      </Route>
      <Route path="/job/:id" element={<JobApplication />} />
      <Route path="/job-list" element={<JobPosting />} />
      <Route path="/contact" element={<Contact />} />

      {/* Employer Routes */}
      <Route path="/register" element={<EmployerRegister />} />
      <Route
        path="/register/verify-email"
        element={
          <EmployerConfirmationCode
            key="register_mail"
            type="mail"
            action="register"
          />
        }
      />
      <Route
        path="/register/verify-phone"
        element={
          <EmployerConfirmationCode
            key="register_phone"
            type="phone"
            action="register"
          />
        }
      />

      <Route path="/employer/login" element={<EmployerLogin />} />
      <Route
        path="/employer/FreelancerProfile/:id"
        element={<FreelancerProfile />}
      />
      <Route
        path="/employer/confirmation-code"
        element={<EmployerConfirmationCode />}
      />
      <Route
        path="/employer/reset-password"
        element={<EmployerResetPassword />}
      />
      <Route
        path="/employer/password-renewal"
        element={<EmployerPasswordRenewal />}
      />

      <Route path="/employer/company-info" element={<CompanyInfo />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="user-info" element={<UserInformation />} />
        <Route
          path="corporate-information"
          element={<CorporateInformation />}
        />

        <Route path="messages" element={<MyMessages />} />
        <Route path="documents" element={<UserDocuments />} />
        <Route path="create-job-posting" element={<CreateJobPosting />} />
        <Route path="job-applications" element={<JobApplications />} />
        <Route path="search-workspace" element={<SearchWorkspace />} />
        <Route path="settings" element={<Settings />} />
        <Route path="support-center" element={<SupportCenter />} />
        <Route path="/employer/company-info" element={<CompanyInfo />} />
        <Route path="colleague" element={<Colleague />} />
        <Route
          path="colleague/edit/ColleagueEdit/:id"
          element={<ColleagueEditPage />}
        />
        {/* Çalışma Arkadaşlarım Sayfası */}
        <Route path="add-colleague" element={<ColleagueAdd />} />
        {/* Çalışma Arkadaşı Ekle Sayfası */}
        <Route path="activeJobAds" element={<ActiveJobs />} />
      </Route>
      <Route
        path="/checkout-plan/:id"
        element={
          <ProtectedRoute>
            <CheckoutPlan />
          </ProtectedRoute>
        }
      />
      <Route
        path="/checkout-job/:id"
        element={
          <ProtectedRoute>
            <CheckoutJob />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default RoutesConfig;
