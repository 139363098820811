import { NavSection } from '@/types/navbar'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

interface SidebarProps {
  navSections: NavSection[]
}

const Sidebar: React.FC<SidebarProps> = ({ navSections }) => {
  const [activeLink, setActiveLink] = useState<number>(0)
  const [activeSection, setActiveSection] = useState<number>(0)
  const [openMobileSidebar, setOpenMobileSidebar] = useState<boolean>(false)

  useEffect(() => {
    const path = window.location.pathname.split('/')
    const activeLinkIndex = navSections.findIndex((section) =>
      section.items.find((item) => item.href === path[path.length - 1]));
    if(activeLinkIndex == -1)
      return

    const activeLink = navSections[activeLinkIndex].items.findIndex((item) => item.href === path[path.length - 1])
    
    if(activeLink == -1)
      return
    setActiveLink(activeLink)
  
    setActiveSection(activeLinkIndex)


  }
    , [ activeSection, navSections])

  const handleClick = (sectionIndex: number, itemIndex: number) => {
    setActiveSection(sectionIndex)
    setActiveLink(itemIndex)
  }

  const handleMobileSidebarOnClick = () => {
    console.log('called')
    setOpenMobileSidebar(!openMobileSidebar)
  }
  return (
    <div className="dashboard-sidebar">
      <div
        className="dashboard-sidebar-inner"
        data-simplebar="init"
      >
        <div
          className="simplebar-track vertical"
          style={{ visibility: 'visible' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ visibility: 'visible', top: '0px', height: '567px' }}
          ></div>
        </div>

        <div
          className="simplebar-track horizontal"
          style={{ visibility: 'visible' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ visibility: 'visible', left: '0px', width: '25px' }}
          ></div>
        </div>

        <div
          className="simplebar-scroll-content"
          style={{ paddingRight: '19px', marginBottom: '-38px' }}
        >
          <div
            className="simplebar-content"
            style={{ paddingBottom: '19px', marginRight: '-18px' }}
          >
            <div className="dashboard-nav-container">
              <a
                href="#"
                className={`dashboard-responsive-nav-trigger ${
                  openMobileSidebar ? 'active' : ''
                }`}
                onClick={handleMobileSidebarOnClick}
              >
                <span className="hamburger hamburger--collapse">
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </span>
                <span className="trigger-title">Kontrol Paneli</span>
              </a>

              <div
                className={`dashboard-nav ${
                  openMobileSidebar ? 'active' : ''
                }`}
              >
                <div className="dashboard-nav-inner">
                  {navSections.map((section, sectionIndex) => (
                    <ul
                      key={sectionIndex}
                      data-submenu-title={section.title}
                      className={
                        activeSection === sectionIndex
                          ? 'active-section'
                          : ''
                      }
                    >
                      {section.items.map((item, itemIndex) => (
                        <li
                          key={`${sectionIndex}-${itemIndex}`}
                          className={
                            activeLink === itemIndex &&
                            activeSection === sectionIndex
                              ? 'active'
                              : ''
                          }
                        >
                          <Link
                            to={item.href}
                            onClick={() =>
                              handleClick(sectionIndex, itemIndex)
                            }
                          >
                            <i className={item.iconClass}></i> {item.label}{' '}
                            {item.tag && (
                              <span className="nav-tag">{item.tag}</span>
                            )}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
