// import Dropdown from '@/components/Dropdown'
// import { COMPANY_OPTIONS } from '@/libs/constants'
// import React, { useState } from 'react'

// type AccordionIndex = number | null

// const SupportCenter: React.FC = () => {
//   // States for the left and right accordions
//   const [activeLeftAccordion, setActiveLeftAccordion] =
//     useState<AccordionIndex>(1)
//   const [activeRightAccordion, setActiveRightAccordion] =
//     useState<AccordionIndex>(4) // Starts with Panel 4
//   const [activeLeftSubAccordion, setActiveLeftSubAccordion] =
//     useState<AccordionIndex>(null)
//   const [activeRightSubAccordion, setActiveRightSubAccordion] =
//     useState<AccordionIndex>(null)

//   const toggleLeftAccordion = (index: number) => {
//     setActiveLeftAccordion(activeLeftAccordion === index ? null : index)
//     setActiveLeftSubAccordion(null) // Close sub-accordions when toggling main accordion
//   }

//   const toggleRightAccordion = (index: number) => {
//     setActiveRightAccordion(activeRightAccordion === index ? null : index)
//     setActiveRightSubAccordion(null) // Close sub-accordions when toggling main accordion
//   }

//   const toggleLeftSubAccordion = (index: number) => {
//     setActiveLeftSubAccordion(activeLeftSubAccordion === index ? null : index)
//   }

//   const toggleRightSubAccordion = (index: number) => {
//     setActiveRightSubAccordion(activeRightSubAccordion === index ? null : index)
//   }

//   return (
//     <div className="row">
//       <div className="col-md-12">
//         <div className="col-md-12 dashboard-box margin-top-0">
//           {/* Headline */}
//           <div className="headline">
//             <h3>
//               <i className="icon-line-awesome-question-circle"></i> Sıkça Sorulan
//               Sorular
//             </h3>
//           </div>

//           <div className="content">
//             <ul className="fields-ul">
//               <li>
//                 <div className="row">
//                   <div className="col-md-6">
//                     <div className="submit-field">
//                       <div className="accordion js-accordion">
//                         {/* Left Accordion Items */}
//                         {[
//                           "Hizmet Sözleşmeleri Hakkında Sıkça Sorulan Sorular",
//                           "İmzalanmış Belgelerle İlgili Sıkça Sorulan Sorular",
//                           "Hizmetlerimizle İlgili Genel Sorular"
//                         ].map((title, panelIndex) => (
//                           <div
//                             key={panelIndex}
//                             className={`accordion__item js-accordion-item ${
//                               activeLeftAccordion === panelIndex ? 'active' : ''
//                             }`}
//                           >
//                             <div
//                               className="accordion-header js-accordion-header"
//                               onClick={() => toggleLeftAccordion(panelIndex)}
//                             >
//                               {title} {/* Güncellenmiş Başlık */}
//                             </div>

//                             {/* Accordion Body */}
//                             <div
//                               className="accordion-body js-accordion-body"
//                               style={{
//                                 display:
//                                   activeLeftAccordion === panelIndex
//                                     ? 'block'
//                                     : 'none'
//                               }}
//                             >
//                               <div className="accordion-body__contents">
//                                 {panelIndex === 0 && (
//                                   'Hizmet sözleşmeleri hakkında en sık sorulan sorulara buradan ulaşabilirsiniz.'
//                                 )}
//                                 {panelIndex === 1 && (
//                                   <>
//                                     İmzalanmış belgelerle ilgili sıkça sorulan sorular.
//                                     <div className="accordion js-accordion">
//                                       {[1, 2].map((subPanelIndex) => (
//                                         <div
//                                           key={subPanelIndex}
//                                           className={`accordion__item js-accordion-item ${
//                                             activeLeftSubAccordion === subPanelIndex
//                                               ? 'active'
//                                               : ''
//                                           }`}
//                                         >
//                                           <div
//                                             className="accordion-header js-accordion-header"
//                                             onClick={() =>
//                                               toggleLeftSubAccordion(subPanelIndex)
//                                             }
//                                           >
//                                             Alt Başlık {subPanelIndex}
//                                           </div>
//                                           <div
//                                             className="accordion-body js-accordion-body"
//                                             style={{
//                                               display:
//                                                 activeLeftSubAccordion ===
//                                                 subPanelIndex
//                                                   ? 'block'
//                                                   : 'none'
//                                             }}
//                                           >
//                                             <div className="accordion-body__contents">
//                                               {subPanelIndex === 1 &&
//                                                 'İmzalanmış belgelerinizi nasıl bulabilirsiniz?'}
//                                               {subPanelIndex === 2 &&
//                                                 'İmzalanmış belgelerimin geçerliliği nedir?'}
//                                             </div>
//                                           </div>
//                                         </div>
//                                       ))}
//                                     </div>
//                                   </>
//                                 )}
//                                 {panelIndex === 2 && (
//                                   'Hizmetlerimizle ilgili sıkça sorulan soruları buradan bulabilirsiniz.'
//                                 )}
//                               </div>
//                             </div>
//                             {/* Accordion Body / End */}
//                           </div>
//                         ))}
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <div className="submit-field">
//                       <div className="accordion js-accordion">
//                         {/* Right Accordion Items */}
//                         {[
//                           "Kullanım Şartları Hakkında Sıkça Sorulan Sorular",
//                           "Müşteri Desteği ile İlgili Sorular",
//                           "Faturalama ve Ödeme İle İlgili Sorular"
//                         ].map((title, panelIndex) => (
//                           <div
//                             key={panelIndex}
//                             className={`accordion__item js-accordion-item ${
//                               activeRightAccordion === panelIndex + 3
//                                 ? 'active'
//                                 : ''
//                             }`}
//                           >
//                             <div
//                               className="accordion-header js-accordion-header"
//                               onClick={() =>
//                                 toggleRightAccordion(panelIndex + 3)
//                               }
//                             >
//                               {title} {/* Güncellenmiş Başlık */}
//                             </div>

//                             {/* Accordion Body */}
//                             <div
//                               className="accordion-body js-accordion-body"
//                               style={{
//                                 display:
//                                   activeRightAccordion === panelIndex + 3
//                                     ? 'block'
//                                     : 'none'
//                               }}
//                             >
//                               <div className="accordion-body__contents">
//                                 {panelIndex === 0 && (
//                                   'Kullanım şartları ile ilgili sıkça sorulan sorular.'
//                                 )}
//                                 {panelIndex === 1 && (
//                                   <>
//                                     Müşteri desteği ile ilgili sıkça sorulan sorular.
//                                     <div className="accordion js-accordion">
//                                       {[1, 2].map((subPanelIndex) => (
//                                         <div
//                                           key={subPanelIndex}
//                                           className={`accordion__item js-accordion-item ${
//                                             activeRightSubAccordion ===
//                                             subPanelIndex + 3
//                                               ? 'active'
//                                               : ''
//                                           }`}
//                                         >
//                                           <div
//                                             className="accordion-header js-accordion-header"
//                                             onClick={() =>
//                                               toggleRightSubAccordion(
//                                                 subPanelIndex + 3
//                                               )
//                                             }
//                                           >
//                                             Alt Başlık {subPanelIndex}
//                                           </div>
//                                           <div
//                                             className="accordion-body js-accordion-body"
//                                             style={{
//                                               display:
//                                                 activeRightSubAccordion ===
//                                                 subPanelIndex + 3
//                                                   ? 'block'
//                                                   : 'none'
//                                             }}
//                                           >
//                                             <div className="accordion-body__contents">
//                                               {subPanelIndex === 1 &&
//                                                 'Müşteri desteğine nasıl ulaşabilirim?'}
//                                               {subPanelIndex === 2 &&
//                                                 'Destek taleplerine ne kadar sürede dönüş alırım?'}
//                                             </div>
//                                           </div>
//                                         </div>
//                                       ))}
//                                     </div>
//                                   </>
//                                 )}
//                                 {panelIndex === 2 && (
//                                   'Faturalama ve ödeme ile ilgili sıkça sorulan sorular.'
//                                 )}
//                               </div>
//                             </div>
//                             {/* Accordion Body / End */}
//                           </div>
//                         ))}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </div>

//         <div className="col-md-12 dashboard-box margin-top-30">
//           {/* Kimlik Bilgileri */}
//           <div className="headline">
//             <h3>
//               <i className="icon-feather-message-square"></i> Bize
//               Yazın
//             </h3>
//           </div>
//           <div className="content with-padding padding-bottom-0">
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="row">
//                   <div className="col-md-12">
//                     <div className="submit-field">
//                       <h5>Mesaj Konusu</h5>

//                       <input type="text" className="with-border" />
//                     </div>
//                   </div>
//                   <div className="col-md-12">
//                     <div className="submit-field">
//                       <h5>Mesaj</h5>
//                       <textarea
//                         cols={30}
//                         rows={5}
//                         className="with-border"
//                         placeholder="Görüş, öneri veya şikayetlerini bizimle paylaş"
//                       ></textarea>
//                     </div>
//                   </div>
//                   <div className="col-md-12">
//                     <div className="submit-field">
//                       <div className="uploadButton margin-top-8">
//                         <input
//                           className="uploadButton-input"
//                           type="file"
//                           accept="image/*, application/pdf"
//                           id="upload"
//                           multiple
//                         />
//                         <label
//                           className="uploadButton-button ripple-effect"
//                           htmlFor="upload"
//                         >
//                           Görsel Ekle
//                         </label>
//                         <span className="uploadButton-file-name">
//                           Mesaj içeriğini destekleyecek görsel ekleyebilirsin.
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-3">
//                     <button
//                       className="button full-width button-sliding-icon ripple-effect margin-top-8 margin-bottom-14"
//                       type="submit"
//                       form="login-form"
//                     >
//                       Mesajı Gönder{' '}
//                       <i className="icon-material-outline-arrow-right-alt"></i>
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//     </div>
//   )
// }

// export default SupportCenter

import React, { useState, useEffect } from "react";
import { fetchFAQ } from "@/api/public";

type FaqItem = {
  question: string;
  answer: string;
  file_id: number;
};

const SupportCenter: React.FC = () => {
  // Akordeon için aktif panelin durumu
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
  const [faqData, setFaqData] = useState<FaqItem[]>([]); // Backend'den dönen veriler

  // Backend'den veri çekme
  useEffect(() => {
    const fetchFaqData = async () => {
      try {
        const response = await fetchFAQ(); // Backend API çağrısı
        if (response.status === "0") {
          setFaqData(response.data.faqs); // Backend'den dönen veriyi kaydet
        } else {
          console.error("FAQ verisi alınamadı:", response.message);
        }
      } catch (error) {
        console.error("FAQ verisi alınamadı:", error);
      }
    };

    fetchFaqData();
  }, []);

  // Akordeonu aç/kapat
  const toggleAccordion = (index: number) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="col-md-12 dashboard-box margin-top-0">
          {/* Başlık */}
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-question-circle"></i> Sıkça
              Sorulan Sorular
            </h3>
          </div>

          <div className="content">
            <ul className="fields-ul">
              <li>
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <div className="accordion js-accordion">
                        {faqData.map((item, index) => (
                          <div
                            key={index}
                            className={`accordion__item js-accordion-item ${
                              activeAccordion === index ? "active" : ""
                            }`}
                          >
                            {/* Akordeon Başlık */}
                            <div
                              className="accordion-header js-accordion-header"
                              onClick={() => toggleAccordion(index)}
                            >
                              {item.question}
                            </div>

                            {/* Akordeon İçerik */}
                            <div
                              className="accordion-body js-accordion-body"
                              style={{
                                display:
                                  activeAccordion === index ? "block" : "none",
                              }}
                            >
                              <div className="accordion-body__contents">
                                {item.answer}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-12 dashboard-box margin-top-30">
          {/* Kimlik Bilgileri */}{" "}
          <div className="headline">
            {" "}
            <h3>
              <i className="icon-feather-message-square"></i> Bize Yazın{" "}
            </h3>{" "}
          </div>{" "}
          <div className="content with-padding padding-bottom-0">
            {" "}
            <div className="row">
              {" "}
              <div className="col-md-12">
                {" "}
                <div className="row">
                  {" "}
                  <div className="col-md-12">
                    {" "}
                    <div className="submit-field">
                      <h5>Mesaj Konusu</h5>
                      <input type="text" className="with-border" />{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-md-12">
                    {" "}
                    <div className="submit-field">
                      <h5>Mesaj</h5>{" "}
                      <textarea
                        cols={30}
                        rows={5}
                        className="with-border"
                        placeholder="Görüş, öneri veya şikayetlerini bizimle paylaş"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submit-field">
                      <div className="uploadButton margin-top-8">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          Görsel Ekle
                        </label>
                        <span className="uploadButton-file-name">
                          Mesaj içeriğini destekleyecek görsel ekleyebilirsin.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <button
                      className="button full-width button-sliding-icon ripple-effect margin-top-8 margin-bottom-14"
                      type="submit"
                      form="login-form"
                    >
                      Mesajı Gönder{" "}
                      <i className="icon-material-outline-arrow-right-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default SupportCenter;
