import {
  fetchDocuments,
  fetchRequestDocument,
  fetchUploadDocument,
} from "@/api/user";
import Dropdown from "@/components/Dropdown";
import { DOCUMENT_TYPES, DocumentUpload_List } from "@/libs/constants";
import { toast } from "react-toastify";
import React, { useState } from "react";
import ReactTimeAgo from "react-time-ago";

type AccordionIndex = number | null;

const UserDocuments = () => {
  const [documents, setDocuments] = React.useState<any[]>([]);

  React.useEffect(() => {
    const handleFetchDocuments = async () => {
      const response = await fetchDocuments();
      setDocuments(response.data);
    };
    handleFetchDocuments();
  }, []);

  const [uploadDocument, setUploadDocument] = React.useState<any>({});
  const [requestDocument, setRequestDocument] = React.useState<any>({});

  const handleRequestDocument = async () => {
    const response = (await fetchRequestDocument(
      requestDocument.type,
      requestDocument.description
    )) as any;
    if (response.status === "0") {
      toast.success("Belge talebiniz başarıyla oluşturuldu.");
    } else {
      toast.error("Belge talebi oluşturulurken bir hata oluştu.");
    }
  };

  const handleUploadDocument = async () => {
    const reader = new FileReader();
    reader.readAsDataURL(uploadDocument.file);
    reader.onloadend = function () {
      const base64data = reader.result as string;
      if (!base64data) {
        toast.error("Belge okunurken bir hata oluştu.");
        return;
      }
      fetchUploadDocument(
        uploadDocument.file.name,
        uploadDocument.file.type,
        base64data,
        uploadDocument.type,
        uploadDocument.startDate,
        uploadDocument.endDate,
        uploadDocument.description
      ).then((response: any) => {
        if (response.status === "0") {
          toast.success("Belge başarıyla yüklendi.");
        } else {
          toast.error("Belge yüklenirken bir hata oluştu.");
        }
      });
    };
  };
  // States for the left and right accordions
  const [activeLeftAccordion, setActiveLeftAccordion] = useState<string | null>(
    null
  );
  const [activeLeftSubAccordion, setActiveLeftSubAccordion] =
    useState<AccordionIndex>(null);
  const [activeRightSubAccordion, setActiveRightSubAccordion] =
    useState<AccordionIndex>(null);

  const toggleLeftAccordion = (index: string) => {
    setActiveLeftAccordion(activeLeftAccordion === index ? null : index);
    setActiveLeftSubAccordion(null); // Close sub-accordions when toggling main accordion
  };

  const toggleLeftSubAccordion = (index: number) => {
    setActiveLeftSubAccordion(activeLeftSubAccordion === index ? null : index);
  };

  const toggleRightSubAccordion = (index: number) => {
    setActiveRightSubAccordion(
      activeRightSubAccordion === index ? null : index
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="col-md-12 dashboard-box margin-top-0">
          {/* Headline */}
          <div className="headline">
            <h3>
              <i className="icon-feather-file-text"></i> Belgelerim
            </h3>
          </div>

          <div className="content">
            {/* Belge yoksa uyarı mesajı göster */}
            {documents.length === 0 ? (
              <ul className="dashboard-box-list">
                <li>Henüz bir belgeniz bulunmamaktadır</li>
              </ul>
            ) : (
              <ul className="fields-ul">
                <li>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="submit-field">
                        <div className="accordion js-accordion">
                          {/* Sol Akordeon Items */}
                          {documents.map((group, index) => (
                            <div
                              key={group.name}
                              className={`accordion__item js-accordion-item ${
                                activeLeftAccordion === group.name
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div
                                className="accordion-header js-accordion-header"
                                onClick={() => toggleLeftAccordion(group.name)}
                              >
                                {group.name} {/* Güncellenmiş Başlık */}
                              </div>

                              {/* Accordion Body */}
                              <div
                                className="accordion-body js-accordion-body"
                                style={{
                                  display:
                                    activeLeftAccordion === group.name
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <div className="accordion-body__contents">
                                  {
                                    // Gelecekteki Sözleşmeler
                                    <div className="content">
                                      <ul className="dashboard-box-list">
                                        {group.documents.map(
                                          (document: any) => (
                                            <li key={"document-" + document.id}>
                                              <div className="job-listing">
                                                <div className="job-listing-details">
                                                  <div className="job-listing-description">
                                                    <h3 className="job-listing-title">
                                                      <a href="#">
                                                        {
                                                          DOCUMENT_TYPES.find(
                                                            (type) =>
                                                              type.value ===
                                                              document.category
                                                          )?.label
                                                        }
                                                      </a>
                                                      <span className="dashboard-status-button green">
                                                        {document.status ===
                                                        "DOCUMENT_REQUESTED"
                                                          ? "Beklemede"
                                                          : "Onaylandı"}
                                                      </span>
                                                    </h3>

                                                    <div className="job-listing-footer">
                                                      <ul>
                                                        <li>
                                                          <i className="icon-material-outline-date-range"></i>
                                                          <ReactTimeAgo
                                                            date={
                                                              new Date(
                                                                document.created_at
                                                              )
                                                            }
                                                            locale="tr"
                                                          />
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              {document.file && (
                                                <div className="buttons-to-right always-visible">
                                                  <a
                                                    href={document.file.url}
                                                    target="_blank"
                                                    className="button ripple-effect"
                                                  >
                                                    <i className="icon-feather-download"></i>{" "}
                                                    Belgeni İndir
                                                  </a>

                                                  <a
                                                    href="#"
                                                    className="button red ripple-effect ico"
                                                    data-tippy-placement="top"
                                                    data-tippy=""
                                                    data-original-title="Belgeyi Sil"
                                                  >
                                                    <i className="icon-feather-trash-2"></i>
                                                  </a>
                                                </div>
                                              )}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  }
                                </div>
                              </div>
                              {/* Accordion Body / End */}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="dashboard-box margin-top-30">
          <div className="headline">
            <h3>
              <i className="icon-feather-file-plus"></i> Belge Ekle
            </h3>
          </div>
          <div className="content with-padding">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <Dropdown
                        options={DocumentUpload_List}
                        onSelect={(value) => {
                          setUploadDocument({ ...uploadDocument, type: value });
                        }}
                        placeholder="Belge Türü"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Belge Veriliş Zamanı</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="text"
                        placeholder="gg/aa/yyyy"
                        onChange={(e) => {
                          setUploadDocument({
                            ...uploadDocument,
                            startDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="submit-field">
                      <h5>Belge Bitiş Zamanı(Varsa)</h5>
                      <input
                        className="with-border"
                        id="birthDate"
                        name="profile-birthday"
                        type="text"
                        placeholder="gg/aa/yyyy"
                        onChange={(e) => {
                          setUploadDocument({
                            ...uploadDocument,
                            endDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8 col-md-6 col-sm-6">
                    <div className="submit-field">
                      <div className="uploadButton margin-top-10">
                        <input
                          className="uploadButton-input"
                          type="file"
                          accept="image/*, application/pdf"
                          id="upload"
                          onChange={(e) => {
                            if (e.target.files) {
                              setUploadDocument({
                                ...uploadDocument,
                                file: e.target.files[0],
                              });
                            }
                          }}
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          <i className="icon-feather-upload"></i> Belge Yükle
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Açıklama</h5>
                      <textarea
                        onChange={(e) => {
                          setUploadDocument({
                            ...uploadDocument,
                            description: e.target.value,
                          });
                        }}
                        cols={30}
                        rows={4}
                        className="with-border"
                        placeholder="Ekleyeceğiniz belge ile ilgili ek bilgi vermek isterseniz buraya yazabilirsiniz."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-3 col-sm-3">
                <button
                  disabled={
                    !uploadDocument ||
                    !uploadDocument.type ||
                    !uploadDocument.startDate ||
                    !uploadDocument.file
                  }
                  onClick={() => handleUploadDocument()}
                  className="popup-with-zoom-anim button full-width button-sliding-icon ripple-effect margin-top-30"
                >
                  Belgeyi Ekle{" "}
                  <i className="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="dashboard-box margin-top-30">
          {/* Belge İste */}
          <div className="headline">
            <h3>
              <i className="icon-line-awesome-file-pdf-o"></i> Belge İste
            </h3>
          </div>
          <div className="content with-padding">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <Dropdown
                        options={DOCUMENT_TYPES}
                        placeholder="Belge Türü"
                        onSelect={(value) => {
                          setRequestDocument({
                            ...requestDocument,
                            type: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 margin-top-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="submit-field">
                      <h5>Açıklama</h5>
                      <textarea
                        onChange={(e) => {
                          setRequestDocument({
                            ...requestDocument,
                            description: e.target.value,
                          });
                        }}
                        cols={30}
                        rows={1}
                        className="with-border"
                        placeholder="Talep edeceğin belge ile ilgili açıklama yazmak istersen burayı kullanabilirsin."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <button
                  onClick={() => handleRequestDocument()}
                  disabled={!requestDocument || !requestDocument.type}
                  className="popup-with-zoom-anim button full-width button-sliding-icon ripple-effect margin-top-30"
                >
                  Belge İste{" "}
                  <i className="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDocuments;
