import React, { useState, useEffect } from "react";
import { fetchCompanyJobAds, fetchDeleteJob, fetchPauseStartJob } from "@/api/company";
import { Link } from "react-router-dom";
import { JOB_STATUS } from "@/libs/constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

interface ApiResponse {
  data: {
    jobs: Job[];
  };
  status: string;
  message: string;
  error_code: string;
}
interface Job {
  id: number;
  title: string;
  salary: number;
  province: {
    name: string;
  };
  created_at: string;
  end_date: string;
  status: string;
  business_area: {
    name: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Company {
  id: number;
  name: string;
}
const MySwal = withReactContent(Swal);
const ActiveJobs = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = (await fetchCompanyJobAds(0, 100, "")) as any; // API isteği başlatılır
        setJobs(response.jobs); // API'den gelen iş ilanlarını state'e set ediyoruz
        setLoading(false);
      } catch (err) {
        setError("Veriler alınırken hata oluştu");
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleDeleteJob = async (jobId: number) => {
    try {
      const response = await fetchDeleteJob(jobId);
      if (response.status === "0"){
        toast.success("İş ilanı başarıyla silindi");
      }else{
        toast.error("İş ilanı silinirken bir hata oluştu");
      }
    } catch (error) {
      toast.error("İş ilanı silinirken bir hata oluştu");
    }
  }
  const handlePauseStartJob = async (jobId: number) => {
    try {
      const response = await fetchPauseStartJob(jobId);
      if (response.status === "0"){
        toast.success("İş ilanı başarıyla durduruldu");
      }else{
        toast.error("İş ilanı durdurulurken bir hata oluştu");
      }
    } catch (error) {
      toast.error("İş ilanı durdurulurken bir hata oluştu");
    }
  }

  if (loading) return <div>Yükleniyor...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div className="row no-message-box">
        <div className="col-md-12">
          <div className="dashboard-box margin-top-0">
            <div className="headline">
              <h3>
                <i className="icon-line-awesome-calendar-check-o"></i> Aktif
                İlanlar
              </h3>
            </div>

            <div className="content">
            {jobs.length === 0 ? (
                <ul className="dashboard-box-list">
                  <li>
                    <div className="job-listing">
                      <div className="job-listing-details">
                        <div className="job-listing-description">
                          <h3 className="job-listing-title">
                            <a>Üzgünüz, henüz hiç aktif ilanınız yok.</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              ) : (
                <ul className="dashboard-box-list">
                  {jobs.map((job) => (
                    <li key={job.id}>
                      <div className="job-listing">
                        <div className="job-listing-details">
                          <div className="job-listing-description">
                            <h3 className="job-listing-title">
                              <Link to={'/job/' + job.id} target="_blank">{job.title}</Link>{" "}
                              <span className="dashboard-status-button yellow">
                                {job.business_area.name}
                              </span>
                              <span
                                className={`dashboard-status-button ${
                                  job.status === "ACTIVE" ? "green" : "red"
                                }`}
                              >
                                {JOB_STATUS[job.status as keyof typeof JOB_STATUS]}
                              </span>
                              

                            </h3>
                            <div className="job-listing-footer">
                              <ul>
                                <li>
                                  <i className="icon-material-outline-date-range"></i>{" "}
                                  İlan Tarihi{" "}
                                  {new Date(
                                    job.created_at
                                  ).toLocaleDateString()}
                                </li>
                                <li>
                                  <i className="icon-material-outline-date-range"></i>{" "}
                                  İlan Bitiş Tarihi{" "}
                                  {new Date(job.end_date).toLocaleDateString()}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="buttons-to-right always-visible">
                        {job.status == 'WAITING_FOR_PAYMENT' && (
                          <Link
                          to={`/checkout-job/${job.id}`}
                          className="button ripple-effect"
                        >
                          <i className="icon-line-awesome-fax"></i>{" "}
                          Ödeme yap{" "}
                        </Link>
                        )}

 {job.status == "PAUSED" && (
                        <a 
                        onClick={() => {
                          Swal.fire({
                            title: "İlanı yayımak istediğinize emin misiniz?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#d33",
                            cancelButtonColor: "#3085d6",
                            confirmButtonText: "Evet, Yayımla!",
                            cancelButtonText: "İptal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              handlePauseStartJob(job.id);
                            }
                          }
                          );
                        }}
                        
                        className="button dark ripple-effect">
                          <i className="icon-feather-play"></i> Yayımla
                        </a>)}
                        {job.status == 'EXPIRED' && (

                        <a href="#" className="button dark ripple-effect">
                        <i className="icon-feather-rotate-ccw"></i> Süreyi Uzat
                      </a>
                        )}
                        {job.status == 'ACTIVE' && (
<>
                       

                        <a 
                        onClick={() => {
                          Swal.fire({
                            title: "İlanı durdurmak istediğinize emin misiniz?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#d33",
                            cancelButtonColor: "#3085d6",
                            confirmButtonText: "Evet, Durdur!",
                            cancelButtonText: "İptal",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              handlePauseStartJob(job.id);
                            }
                          });
                        
                        }}

                        className="button dark ripple-effect">
                          <i className="icon-feather-pause"></i> Durdur
                        </a>

                        <a
                          href="#"
                          className="button gray ripple-effect"
                          data-tippy-placement="top"
                          data-original-title="Edit"
                        >
                          <i className="icon-feather-edit"></i> Düzenle
                        </a>

                        <a
                          className="button gray ripple-effect"
                          data-tippy-placement="top"
                          data-original-title="Remove"
                          onClick={() => {
                        
                            MySwal.fire({
                              title:
                                "İlanı silmek istediğinize emin misiniz?",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#d33",
                              cancelButtonColor: "#3085d6",
                              confirmButtonText: "Evet, Sil!",
                              cancelButtonText: "İptal",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDeleteJob(job.id);
                              }
                            })

                          }}
                        
                        >
                          <i className="icon-feather-trash-2"></i> Sil
                        </a>
                        </>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
     

      <style>{`
        .listings-container {
          display: grid;
          grid-template-columns: 1fr; /* Tek sütun */

        }

        .job-listing {
          display: flex;
          flex-direction: column;

        }

        .job-listing-details {
          display: flex;
          align-items: center;
        }


      `}</style>
    </>
  );
};

export default ActiveJobs;
