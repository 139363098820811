// import playstoreImg from "@/images/get-play-store.png";
// import appstoreImg from "@/images/get-app-store.svg";
// import companyLogo3 from "@/images/company-logo-03.png";
// import { useState } from "react";
// import Dropdown from "@/components/Dropdown";
// import {
//   JOB_TYPE_OPTIONS,
//   jobListings,
//   POSITION_OPTIONS,
// } from "@/libs/constants";
// import { Link } from "react-router-dom";
// import MultiRangeSlider from "@/components/MultiRangeSlider";
// import React from "react";
// import { fetchPublicJobs } from "@/api/jobs";
// import { toast } from "react-toastify";
// import ReactTimeAgo from "react-time-ago";
// import { fetchBusinesAreaOptions, fetchProvince } from "@/api/public";
// import DropdownWithSearch from "@/components/DropdownWithSearch";

// const JobPosting = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedValue, setSelectedValue] = useState("Şehir");
//   const [selectedGender, setSelectedGender] = useState("Tümü"); // Default value
//   const [jobs, setJobs] = useState([]);
//   const [salaryRange, setSalaryRange] = useState<{ min: number; max: number }>({
//     min: 100,
//     max: 10000,
//   });
//   const [jTypeFullTime, setJTypeFullTime] = useState(false);
//   const [jTypePartTime, setJTypePartTime] = useState(false);
//   const [jTypeDaily, setJTypeDaily] = useState(false);
//   const [selectedBusinessArea, setSelectedBusinessArea] = useState<any>(null);
//   const [businessAreas, setBusinessAreas] = useState([]);

//   const [provinces, setProvinces] = useState([]);
//   const [selectedProvince, setSelectedProvince] = useState<any>(null);

//   React.useEffect(() => {
//     const handleFetchProvinces = async () => {
//       const provincesResponse = await fetchProvince();

//       setProvinces(
//         provincesResponse.data.provinces.map((province: any) => ({
//           value: province.id,
//           label: province.name,
//         }))
//       );
//     };
//     handleFetchProvinces();
//   }, []);

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSelectedGender(event.target.value);
//   };
//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleOptionClick = (value: string) => {
//     setSelectedValue(value);
//     setIsOpen(false); // Optionally close the dropdown when an option is selected
//   };

//   const [bookmarkedJobs, setBookmarkedJobs] = useState(
//     new Array(jobListings.length).fill(false)
//   );

//   const toggleBookmark = (
//     index: number,
//     event: React.MouseEvent<HTMLDivElement>
//   ) => {
//     event.preventDefault();
//     setBookmarkedJobs((prev) =>
//       prev.map((isBookmarked, i) =>
//         i === index ? !isBookmarked : isBookmarked
//       )
//     );
//   };

//   React.useEffect(() => {
//     const handleFetchBusinessAreas = async () => {
//       const businessAreasResponse = await fetchBusinesAreaOptions();

//       setBusinessAreas(
//         businessAreasResponse.data.areas.map((businessArea: any) => ({
//           value: businessArea.id,
//           label: businessArea.name,
//         }))
//       );
//     };
//     handleFetchBusinessAreas();
//   }, []);

//   React.useEffect(() => {
//     const handleSearch = async () => {
//       var request: any = {
//         province_id: 80,
//       };

//       var jobTypes = [];
//       if (jTypeFullTime) {
//         jobTypes.push("FULL_TIME");
//       } else if (jTypePartTime) {
//         jobTypes.push("PART_TIME");
//       } else if (jTypeDaily) {
//         jobTypes.push("DAILY");
//       }

//       if (jobTypes.length > 0) {
//         request["types"] = jobTypes;
//       }

//       if (selectedGender !== "Tümü") {
//         request["gender"] = selectedGender === "Erkek" ? "MALE" : "FEMALE";
//       }

//       if (selectedBusinessArea) {
//         request["business_area_id"] = selectedBusinessArea;
//       }

//       const response = await fetchPublicJobs(request);

//       if (response.status === "0") {
//         setJobs(response.data.jobs);
//       } else {
//         toast.error(response.message);
//         setJobs([]);
//       }
//     };

//     handleSearch();
//   }, [
//     selectedGender,
//     selectedValue,
//     isOpen,
//     bookmarkedJobs,
//     jTypeFullTime,
//     jTypePartTime,
//     jTypeDaily,
//     selectedBusinessArea,
//   ]);

//   return (
//     <div className="container padding-top-50">
//       <div className="row">
//         <div className="col-md-3">
//           <div className="sidebar-container">
//             <div className="sidebar-widget">
//               <h3>Şehir</h3>
//               <div className="sidebar-widget">
//                 <DropdownWithSearch
//                   onSelect={(value) => setSelectedProvince(parseInt(value))}
//                   options={provinces}
//                   placeholder="Şehir"
//                 />
//               </div>
//             </div>

//             <div className="sidebar-widget">
//               <h3>Cinsiyet</h3>
//               <div className="keywords-container">
//                 <div className="keyword-input-container">
//                   <div className="radio padding-right-4">
//                     <input
//                       id="radio-1"
//                       name="radio"
//                       type="radio"
//                       value="Tümü"
//                       checked={selectedGender === "Tümü"}
//                       onChange={handleChange}
//                     />
//                     <label htmlFor="radio-1">
//                       <span className="radio-label"></span> Tümü
//                     </label>
//                   </div>
//                   <div className="radio padding-right-4 mx-3">
//                     <input
//                       id="radio-2"
//                       name="radio"
//                       type="radio"
//                       value="Erkek"
//                       checked={selectedGender === "Erkek"}
//                       onChange={handleChange}
//                     />
//                     <label htmlFor="radio-2">
//                       <span className="radio-label"></span> Erkek
//                     </label>
//                   </div>
//                   <div className="radio">
//                     <input
//                       id="radio-3"
//                       name="radio"
//                       type="radio"
//                       value="Kadın"
//                       checked={selectedGender === "Kadın"}
//                       onChange={handleChange}
//                     />
//                     <label htmlFor="radio-3">
//                       <span className="radio-label"></span> Kadın
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="sidebar-widget">
//               <h3>Yaş Aralığı</h3>
//               <div className="margin-top-55"></div>
//               <MultiRangeSlider
//                 min={18}
//                 max={65}
//                 onChange={({ min, max }) => {}}
//               />
//             </div>

//             <div className="sidebar-widget">
//               <h3>Pozisyon</h3>
//               <DropdownWithSearch
//                 onSelect={(value) => setSelectedBusinessArea(parseInt(value))}
//                 options={businessAreas}
//                 placeholder="Pozisyon"
//                 label=""
//               />
//             </div>
//             <div className="sidebar-widget">
//               <h3>Çalışma Şekli</h3>

//               <div className="switches-list">
//                 <div className="switch-container">
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       onChange={(e) => setJTypeFullTime(e.target.checked)}
//                     />
//                     <span className="switch-button"></span> Tam Zamanlı
//                   </label>
//                 </div>

//                 <div className="switch-container">
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       onChange={(e) => setJTypePartTime(e.target.checked)}
//                     />
//                     <span className="switch-button"></span> Yarı Zamanlı
//                   </label>
//                 </div>

//                 <div className="switch-container">
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       onChange={(e) => setJTypeDaily(e.target.checked)}
//                     />
//                     <span className="switch-button"></span> Günlük
//                   </label>
//                 </div>
//               </div>
//             </div>

//             <div className="sidebar-widget">
//               <h3>Saat/Ücret Aralığı</h3>
//               <div className="margin-top-55"></div>
//               <MultiRangeSlider
//                 min={100}
//                 max={10000}
//                 onChange={({ min, max }) => setSalaryRange({ min, max })} // Set salary range from slider
//               />
//             </div>

//             <div className="sidebar-widget">
//               <h3>Çalışma Saatleri</h3>
//               <div className="margin-top-55"></div>
//               <MultiRangeSlider
//                 min={8}
//                 max={24}
//                 onChange={({ min, max }) => {
//                   // You can track working hours here if needed
//                 }}
//               />
//             </div>
//             <div className="padding-bottom-25"></div>
//           </div>
//         </div>
//         <div className="col-md-9 content-left-offset">
//           <h3 className="page-title">Arama Sonuçları</h3>

//           <div className="listings-container margin-top-35 margin-bottom-35">
//             {jobs.map((job: any, index) => (
//               <Link to={`/job/${job.id}`} className="job-listing" key={job.id}>
//                 <div className="job-listing-details">
//                   <div className="job-listing-company-logo">
//                     <img src={companyLogo3} alt="" />
//                   </div>
//                   <div className="job-listing-description">
//                     <h4 className="job-listing-company">
//                       {job.company.name}{" "}
//                       <span
//                         className="verified-badge"
//                         data-tippy-placement="top"
//                         data-tippy=""
//                         data-original-title="Doğrulanmış İşveren"
//                       ></span>
//                     </h4>
//                     <h3 className="job-listing-title">
//                       {job.business_area.name}
//                     </h3>
//                     <p className="job-listing-text">{job.description}</p>
//                   </div>
//                   <div
//                     className="review-job"
//                     onClick={(e) => toggleBookmark(index, e)}
//                   >
//                     <span
//                       className={`bookmark-icon ${
//                         bookmarkedJobs[index] ? "bookmarked" : ""
//                       }`}
//                     ></span>
//                   </div>
//                 </div>
//                 <div className="job-listing-footer">
//                   <ul>
//                     <li>
//                       <i className="icon-material-outline-location-on"></i>{" "}
//                       {job.province.name}
//                     </li>
//                     <li>
//                       <i className="icon-material-outline-business-center"></i>{" "}
//                       {job.type
//                         ? JOB_TYPE_OPTIONS[
//                             job.type as keyof typeof JOB_TYPE_OPTIONS
//                           ]
//                         : ""}
//                     </li>
//                     <li>
//                       <i className="icon-material-outline-access-time"></i>{" "}
//                       <ReactTimeAgo date={job.updated_at} />
//                     </li>
//                   </ul>
//                 </div>
//               </Link>
//             ))}

//             <div className="row">
//               <div className="col-md-12">
//                 <div className="notification notice download">
//                   <p>
//                     Daha fazla iş teklifini görebilmek için{" "}
//                     <strong>ParttimeJET</strong> uygulamasını indirmeniz
//                     gerekmektedir.
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-12">
//                 <div className="get-app">
//                   <div className="get-app-center">
//                     <a href="">
//                       <img
//                         src={appstoreImg}
//                         height="55"
//                         alt="Uygulamamızı Apple Store'den İndir"
//                       />
//                     </a>
//                     <a href="">
//                       <img
//                         src={playstoreImg}
//                         height="55"
//                         alt="Uygulamamızı Google Play'den İndir"
//                       />
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobPosting;

// -----------------------------------

// import playstoreImg from "@/images/get-play-store.png";
// import appstoreImg from "@/images/get-app-store.svg";
// import companyLogo3 from "@/images/company-logo-03.png";
// import { useState } from "react";
// import Dropdown from "@/components/Dropdown";
// import {
//   JOB_TYPE_OPTIONS,
//   jobListings,
//   POSITION_OPTIONS,
// } from "@/libs/constants";
// import { Link } from "react-router-dom";
// import MultiRangeSlider from "@/components/MultiRangeSlider";
// import React from "react";
// import { fetchPublicJobs } from "@/api/jobs";
// import { toast } from "react-toastify";
// import ReactTimeAgo from "react-time-ago";
// import { fetchBusinesAreaOptions, fetchProvince } from "@/api/public";
// import DropdownWithSearch from "@/components/DropdownWithSearch";
// import noAvatar from "../../images/company-logo-05.png";

// const JobPosting = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedValue, setSelectedValue] = useState("Şehir");
//   const [selectedGender, setSelectedGender] = useState("Tümü"); // Default value
//   const [jobs, setJobs] = useState([]);
//   const [salaryRange, setSalaryRange] = useState<{ min: number; max: number }>({
//     min: 100,
//     max: 10000,
//   });
//   const [jTypeFullTime, setJTypeFullTime] = useState(false);
//   const [jTypePartTime, setJTypePartTime] = useState(false);
//   const [jTypeDaily, setJTypeDaily] = useState(false);
//   const [selectedBusinessArea, setSelectedBusinessArea] = useState<any>(null);
//   const [businessAreas, setBusinessAreas] = useState([]);
//   const [provinces, setProvinces] = useState([]);
//   const [selectedProvince, setSelectedProvince] = useState<any>(null);

//   React.useEffect(() => {
//     const handleFetchProvinces = async () => {
//       const provincesResponse = await fetchProvince();

//       setProvinces(
//         provincesResponse.data.provinces.map((province: any) => ({
//           value: province.id,
//           label: province.name,
//         }))
//       );
//     };
//     handleFetchProvinces();
//   }, []);

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSelectedGender(event.target.value);
//   };

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleOptionClick = (value: string) => {
//     setSelectedValue(value);
//     setIsOpen(false); // Optionally close the dropdown when an option is selected
//   };

//   const [bookmarkedJobs, setBookmarkedJobs] = useState(
//     new Array(jobListings.length).fill(false)
//   );

//   const toggleBookmark = (
//     index: number,
//     event: React.MouseEvent<HTMLDivElement>
//   ) => {
//     event.preventDefault();
//     setBookmarkedJobs((prev) =>
//       prev.map((isBookmarked, i) =>
//         i === index ? !isBookmarked : isBookmarked
//       )
//     );
//   };

//   React.useEffect(() => {
//     const handleFetchBusinessAreas = async () => {
//       const businessAreasResponse = await fetchBusinesAreaOptions();

//       setBusinessAreas(
//         businessAreasResponse.data.areas.map((businessArea: any) => ({
//           value: businessArea.id,
//           label: businessArea.name,
//         }))
//       );
//     };
//     handleFetchBusinessAreas();
//   }, []);

//   React.useEffect(() => {
//     const handleSearch = async () => {
//       let request: any = {
//         province_id: selectedProvince ? selectedProvince : 80, // Eğer seçilen bir şehir varsa onu kullan
//       };

//       let jobTypes = [];
//       if (jTypeFullTime) {
//         jobTypes.push("FULL_TIME");
//       }
//       if (jTypePartTime) {
//         jobTypes.push("PART_TIME");
//       }
//       if (jTypeDaily) {
//         jobTypes.push("DAILY");
//       }

//       if (jobTypes.length > 0) {
//         request["types"] = jobTypes;
//       }

//       if (selectedGender !== "Tümü") {
//         request["gender"] = selectedGender === "Erkek" ? "MALE" : "FEMALE";
//       }

//       if (selectedBusinessArea) {
//         request["business_area_id"] = selectedBusinessArea;
//       }

//       // Ücret aralığını filtrelemeye ekle
//       if (salaryRange.min && salaryRange.max) {
//         request["salary_min"] = salaryRange.min;
//         request["salary_max"] = salaryRange.max;
//       }

//       try {
//         const response = await fetchPublicJobs(request);

//         if (response.status === "0") {
//           setJobs(response.data.jobs);
//         } else {
//           toast.error(response.message);
//           setJobs([]);
//         }
//       } catch (error) {
//         toast.error("İş ilanlarını getirirken bir hata oluştu.");
//       }
//     };

//     handleSearch();
//   }, [
//     selectedGender,
//     selectedValue,
//     jTypeFullTime,
//     jTypePartTime,
//     jTypeDaily,
//     selectedBusinessArea,
//     selectedProvince, // Yeni eklenen bağımlılıklar
//     salaryRange, // Ücret aralığı
//   ]);

//   return (
//     <div className="container padding-top-50">
//       <div className="row">
//         <div className="col-md-3">
//           <div className="sidebar-container">
//             <div className="sidebar-widget">
//               <h3>Şehir</h3>
//               <div className="sidebar-widget">
//                 <DropdownWithSearch
//                   onSelect={(value) => setSelectedProvince(parseInt(value))}
//                   options={provinces}
//                   placeholder="Şehir"
//                 />
//               </div>
//             </div>

//             <div className="sidebar-widget">
//               <h3>Cinsiyet</h3>
//               <div className="keywords-container">
//                 <div className="keyword-input-container">
//                   <div className="radio padding-right-4">
//                     <input
//                       id="radio-1"
//                       name="radio"
//                       type="radio"
//                       value="Tümü"
//                       checked={selectedGender === "Tümü"}
//                       onChange={handleChange}
//                     />
//                     <label htmlFor="radio-1">
//                       <span className="radio-label"></span> Tümü
//                     </label>
//                   </div>
//                   <div className="radio padding-right-4 mx-3">
//                     <input
//                       id="radio-2"
//                       name="radio"
//                       type="radio"
//                       value="Erkek"
//                       checked={selectedGender === "Erkek"}
//                       onChange={handleChange}
//                     />
//                     <label htmlFor="radio-2">
//                       <span className="radio-label"></span> Erkek
//                     </label>
//                   </div>
//                   <div className="radio">
//                     <input
//                       id="radio-3"
//                       name="radio"
//                       type="radio"
//                       value="Kadın"
//                       checked={selectedGender === "Kadın"}
//                       onChange={handleChange}
//                     />
//                     <label htmlFor="radio-3">
//                       <span className="radio-label"></span> Kadın
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="sidebar-widget">
//               <h3>Yaş Aralığı</h3>
//               <div className="margin-top-35"></div>
//               <MultiRangeSlider
//                 min={18}
//                 max={65}
//                 onChange={({ min, max }) => {}}
//               />
//             </div>

//             <div className="sidebar-widget">
//               <h3>Pozisyon</h3>
//               <DropdownWithSearch
//                 onSelect={(value) => setSelectedBusinessArea(parseInt(value))}
//                 options={businessAreas}
//                 placeholder="Pozisyon"
//                 label=""
//               />
//             </div>
//             <div className="sidebar-widget">
//               <h3>Çalışma Şekli</h3>

//               <div className="switches-list">
//                 <div className="switch-container">
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       onChange={(e) => setJTypeFullTime(e.target.checked)}
//                     />
//                     <span className="switch-button"></span> Tam Zamanlı
//                   </label>
//                 </div>

//                 <div className="switch-container">
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       onChange={(e) => setJTypePartTime(e.target.checked)}
//                     />
//                     <span className="switch-button"></span> Yarı Zamanlı
//                   </label>
//                 </div>

//                 <div className="switch-container">
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       onChange={(e) => setJTypeDaily(e.target.checked)}
//                     />
//                     <span className="switch-button"></span> Günlük
//                   </label>
//                 </div>
//               </div>
//             </div>

//             <div className="sidebar-widget">
//               <h3>Saat/Ücret Aralığı</h3>
//               <div className="margin-top-55"></div>
//               <MultiRangeSlider
//                 min={100}
//                 max={10000}
//                 onChange={({ min, max }) => setSalaryRange({ min, max })} // Set salary range from slider
//               />
//             </div>

//             <div className="sidebar-widget">
//               <h3>Çalışma Saatleri</h3>
//               <div className="margin-top-55"></div>
//               <MultiRangeSlider
//                 min={8}
//                 max={24}
//                 onChange={({ min, max }) => {
//                   // You can track working hours here if needed
//                 }}
//               />
//             </div>
//             <div className="padding-bottom-25"></div>
//           </div>
//         </div>
//         <div className="col-md-9 content-left-offset">
//           <h3 className="page-title">Arama Sonuçları</h3>

//           <div className="listings-container margin-top-35 margin-bottom-35">
//             {jobs.map((job: any, index) => (
//               <Link to={`/job/${job.id}`} className="job-listing" key={job.id}>
//                 <div className="job-listing-details">
//                   <div className="job-listing-company-logo">
//                     <img src={noAvatar} alt="" />
//                   </div>
//                   <div className="job-listing-description">
//                     <h4 className="job-listing-company">
//                       {job.company.name}{" "}
//                       <span
//                         className="verified-badge"
//                         data-tippy-placement="top"
//                         data-tippy=""
//                         data-original-title="Doğrulanmış İşveren"
//                       ></span>
//                     </h4>
//                     <h3 className="job-listing-title">
//                       {job.business_area.name}
//                     </h3>
//                     <p className="job-listing-text">{job.description}</p>
//                   </div>
//                   <div
//                     className="review-job"
//                     onClick={(e) => toggleBookmark(index, e)}
//                   >
//                     <span
//                       className={`bookmark-icon ${
//                         bookmarkedJobs[index] ? "bookmarked" : ""
//                       }`}
//                     ></span>
//                   </div>
//                 </div>
//                 <div className="job-listing-footer">
//                   <ul>
//                     <li>
//                       <i className="icon-material-outline-location-on"></i>{" "}
//                       {job.province.name}
//                     </li>
//                     <li>
//                       <i className="icon-material-outline-business-center"></i>{" "}
//                       {job.type
//                         ? JOB_TYPE_OPTIONS[
//                             job.type as keyof typeof JOB_TYPE_OPTIONS
//                           ]
//                         : ""}
//                     </li>
//                     <li>
//                       <i className="icon-material-outline-access-time"></i>{" "}
//                       <ReactTimeAgo date={job.updated_at} />
//                     </li>
//                   </ul>
//                 </div>
//               </Link>
//             ))}

//             <div className="row">
//               <div className="col-md-12">
//                 <div className="notification notice download">
//                   <p>
//                     Daha fazla iş teklifini görebilmek için{" "}
//                     <strong>ParttimeJET</strong> uygulamasını indirmeniz
//                     gerekmektedir.
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-12">
//                 <div className="get-app">
//                   <div className="get-app-center">
//                     <a href="">
//                       <img
//                         src={appstoreImg}
//                         height="55"
//                         alt="Uygulamamızı Apple Store'den İndir"
//                       />
//                     </a>
//                     <a href="">
//                       <img
//                         src={playstoreImg}
//                         height="55"
//                         alt="Uygulamamızı Google Play'den İndir"
//                       />
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobPosting;

// ---------------------------------

// import playstoreImg from "@/images/get-play-store.png";
// import appstoreImg from "@/images/get-app-store.svg";
// import companyLogo3 from "@/images/company-logo-03.png";
// import { useState } from "react";
// import Dropdown from "@/components/Dropdown";
// import {
//   JOB_TYPE_OPTIONS,
//   jobListings,
//   POSITION_OPTIONS,
// } from "@/libs/constants";
// import { Link } from "react-router-dom";
// import MultiRangeSlider from "@/components/MultiRangeSlider";
// import React from "react";
// import { fetchPublicJobs } from "@/api/jobs";
// import { toast } from "react-toastify";
// import ReactTimeAgo from "react-time-ago";
// import { fetchBusinesAreaOptions, fetchProvince } from "@/api/public";
// import DropdownWithSearch from "@/components/DropdownWithSearch";
// import noAvatar from "../../images/company-logo-05.png";

// const JobPosting = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedValue, setSelectedValue] = useState("Şehir");
//   const [selectedGender, setSelectedGender] = useState("Tümü"); // Default value
//   const [jobs, setJobs] = useState([]);
//   const [salaryRange, setSalaryRange] = useState<{ min: number; max: number }>({
//     min: 100,
//     max: 10000,
//   });
//   const [jTypeFullTime, setJTypeFullTime] = useState(false);
//   const [jTypePartTime, setJTypePartTime] = useState(false);
//   const [jTypeDaily, setJTypeDaily] = useState(false);
//   const [selectedBusinessArea, setSelectedBusinessArea] = useState<any>(null);
//   const [businessAreas, setBusinessAreas] = useState([]);
//   const [provinces, setProvinces] = useState([]);
//   const [selectedProvince, setSelectedProvince] = useState<any>(null);

//   React.useEffect(() => {
//     const handleFetchProvinces = async () => {
//       const provincesResponse = await fetchProvince();

//       setProvinces(
//         provincesResponse.data.provinces.map((province: any) => ({
//           value: province.id,
//           label: province.name,
//         }))
//       );
//     };
//     handleFetchProvinces();
//   }, []);

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSelectedGender(event.target.value);
//   };

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleOptionClick = (value: string) => {
//     setSelectedValue(value);
//     setIsOpen(false); // Optionally close the dropdown when an option is selected
//   };

//   React.useEffect(() => {
//     const handleFetchBusinessAreas = async () => {
// <<<<<<< HEAD
//       const businessAreasResponse = await fetchBusinesAreaOptions();

//       setBusinessAreas(
//         businessAreasResponse.data.areas.map((businessArea: any) => ({
//           value: businessArea.id,
//           label: businessArea.name,
//         }))
//       );
//     };
//     handleFetchBusinessAreas();
//   }, []);

//   React.useEffect(() => {
//     const handleSearch = async () => {
//       let request: any = {
//         province_id: selectedProvince ? selectedProvince : 80, // Eğer seçilen bir şehir varsa onu kullan
//       };

//       let jobTypes = [];
//       if (jTypeFullTime) {
//         jobTypes.push("FULL_TIME");
//       }
//       if (jTypePartTime) {
//         jobTypes.push("PART_TIME");
//       }
//       if (jTypeDaily) {
//         jobTypes.push("DAILY");
//       }

//       if (jobTypes.length > 0) {
//         request["types"] = jobTypes;
//       }

//       if (selectedGender !== "Tümü") {
//         request["gender"] = selectedGender === "Erkek" ? "MALE" : "FEMALE";
//       }

//       if (selectedBusinessArea) {
//         request["business_area_id"] = selectedBusinessArea;
//       }

//       // Ücret aralığını filtrelemeye ekle
//       if (salaryRange.min && salaryRange.max) {
//         request["salary_min"] = salaryRange.min;
//         request["salary_max"] = salaryRange.max;
//       }

//       try {
//         const response = await fetchPublicJobs(request);

//         if (response.status === "0") {
//           setJobs(response.data.jobs);
//         } else {
//           toast.error(response.message);
//           setJobs([]);
//         }
//       } catch (error) {
//         toast.error("İş ilanlarını getirirken bir hata oluştu.");
//       }
//     };

//     handleSearch();
//   }, [
//     selectedGender,
//     selectedValue,
//     jTypeFullTime,
//     jTypePartTime,
//     jTypeDaily,
//     selectedBusinessArea,
//     selectedProvince, // Yeni eklenen bağımlılıklar
//     salaryRange, // Ücret aralığı
//   ]);
// =======
//       const businessAreasResponse = await fetchBusinesAreaOptions()

//       setBusinessAreas(businessAreasResponse.data.areas.map((businessArea: any) => ({
//         value: businessArea.id,
//         label: businessArea.name
//       })))
//     }
//     handleFetchBusinessAreas()
//   }
//   , []);
// >>>>>>> 007b76dbf86e00269f5de5b5bd1370d96fd55c6f

//   return (
//     <div className="container padding-top-50">
//       <div className="row">
//         <div className="col-md-3">
//           <div className="sidebar-container">
//             <div className="sidebar-widget">
//               <h3>Şehir</h3>
//               <div className="sidebar-widget">
//                 <DropdownWithSearch
//                   onSelect={(value) => setSelectedProvince(parseInt(value))}
//                   options={provinces}
//                   placeholder="Şehir"
//                 />
//               </div>
//             </div>

//             <div className="sidebar-widget">
//               <h3>Cinsiyet</h3>
//               <div className="keywords-container">
//                 <div className="keyword-input-container">
//                   <div className="radio padding-right-4">
//                     <input
//                       id="radio-1"
//                       name="radio"
//                       type="radio"
//                       value="Tümü"
//                       checked={selectedGender === "Tümü"}
//                       onChange={handleChange}
//                     />
//                     <label htmlFor="radio-1">
//                       <span className="radio-label"></span> Tümü
//                     </label>
//                   </div>
//                   <div className="radio padding-right-4 mx-3">
//                     <input
//                       id="radio-2"
//                       name="radio"
//                       type="radio"
//                       value="Erkek"
//                       checked={selectedGender === "Erkek"}
//                       onChange={handleChange}
//                     />
//                     <label htmlFor="radio-2">
//                       <span className="radio-label"></span> Erkek
//                     </label>
//                   </div>
//                   <div className="radio">
//                     <input
//                       id="radio-3"
//                       name="radio"
//                       type="radio"
//                       value="Kadın"
//                       checked={selectedGender === "Kadın"}
//                       onChange={handleChange}
//                     />
//                     <label htmlFor="radio-3">
//                       <span className="radio-label"></span> Kadın
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="sidebar-widget">
//               <h3>Yaş Aralığı</h3>
//               <div className="margin-top-35"></div>
//               <MultiRangeSlider
//                 min={18}
//                 max={65}
//                 onChange={({ min, max }) => {}}
//               />
//             </div>

//             <div className="sidebar-widget">
//               <h3>Pozisyon</h3>
//               <DropdownWithSearch
//                 onSelect={(value) => setSelectedBusinessArea(parseInt(value))}
//                 options={businessAreas}
//                 placeholder="Pozisyon"
//                 label=""
//               />
//             </div>
//             <div className="sidebar-widget">
//               <h3>Çalışma Şekli</h3>

//               <div className="switches-list">
//                 <div className="switch-container">
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       onChange={(e) => setJTypeFullTime(e.target.checked)}
//                     />
//                     <span className="switch-button"></span> Tam Zamanlı
//                   </label>
//                 </div>

//                 <div className="switch-container">
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       onChange={(e) => setJTypePartTime(e.target.checked)}
//                     />
//                     <span className="switch-button"></span> Yarı Zamanlı
//                   </label>
//                 </div>

//                 <div className="switch-container">
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       onChange={(e) => setJTypeDaily(e.target.checked)}
//                     />
//                     <span className="switch-button"></span> Günlük
//                   </label>
//                 </div>
//               </div>
//             </div>

//             <div className="sidebar-widget">
// <<<<<<< HEAD
//               <h3>Saat/Ücret Aralığı</h3>
//               <div className="margin-top-55"></div>
//               <MultiRangeSlider
//                 min={100}
//                 max={10000}
//                 onChange={({ min, max }) => setSalaryRange({ min, max })} // Set salary range from slider
//               />
// =======
//               <h3>İlan Seçimleri</h3>

//               <div className="switches-list">

//                 <div className="switch-container">
//                   <label className="switch">
//                     <input type="checkbox" onChange={(e) => setJTypePartTime(e.target.checked)}/>
//                     <span className="switch-button"></span> Engelliye Uygun İlanlar
//                   </label>
//                 </div>

//                 <div className="switch-container">
//                   <label className="switch">
//                     <input type="checkbox" onChange={(e) => setJTypeDaily(e.target.checked)}/>
//                     <span className="switch-button"></span> Staja Uygun İlanlar
//                   </label>
//                 </div>

//               </div>
// >>>>>>> 007b76dbf86e00269f5de5b5bd1370d96fd55c6f
//             </div>

//             <div className="sidebar-widget">
//               <h3>Çalışma Saatleri</h3>
//               <div className="margin-top-55"></div>
//               <MultiRangeSlider
//                 min={8}
//                 max={24}
//                 onChange={({ min, max }) => {
//                   // You can track working hours here if needed
//                 }}
//               />
//             </div>
//             <div className="padding-bottom-25"></div>
//           </div>
//         </div>
//         <div className="col-md-9 content-left-offset">
//           <h3 className="page-title">Arama Sonuçları</h3>

//           <div className="listings-container margin-top-35 margin-bottom-35">
//             {jobs.map((job: any) => (
//               <Link to={`/job/${job.id}`} className="job-listing" key={job.id}>
//                 <div className="job-listing-details">
//                   <div className="job-listing-company-logo">
//                     <img src={noAvatar} alt="" />
//                   </div>
//                   <div className="job-listing-description">
//                     <h4 className="job-listing-company">
//                       {job.company.name}{" "}
//                       <span
//                         className="verified-badge"
//                         data-tippy-placement="top"
//                         data-tippy=""
//                         data-original-title="Doğrulanmış İşveren"
//                       ></span>
//                     </h4>
//                     <h3 className="job-listing-title">
//                       {job.business_area.name}
//                     </h3>
//                     <p className="job-listing-text">{job.description}</p>
//                   </div>
//                 </div>
//                 <div className="job-listing-footer">
//                   <ul>
//                     <li>
//                       <i className="icon-material-outline-location-on"></i>{" "}
//                       {job.province.name}
//                     </li>
//                     <li>
//                       <i className="icon-material-outline-business-center"></i>{" "}
//                       {job.type
//                         ? JOB_TYPE_OPTIONS[
//                             job.type as keyof typeof JOB_TYPE_OPTIONS
//                           ]
//                         : ""}
//                     </li>
//                     <li>
//                       <i className="icon-material-outline-access-time"></i>{" "}
//                       <ReactTimeAgo date={job.updated_at} />
//                     </li>
//                   </ul>
//                 </div>
//               </Link>
//             ))}

//             <div className="row">
//               <div className="col-md-12">
//                 <div className="notification notice download">
//                   <p>
//                     Daha fazla iş teklifini görebilmek için{" "}
//                     <strong>ParttimeJET</strong> uygulamasını indirmeniz
//                     gerekmektedir.
//                   </p>
//                 </div>
//               </div>
//               <div className="col-md-12">
//                 <div className="get-app">
//                   <div className="get-app-center">
//                     <a href="">
//                       <img
//                         src={appstoreImg}
//                         height="55"
//                         alt="Uygulamamızı Apple Store'den İndir"
//                       />
//                     </a>
//                     <a href="">
//                       <img
//                         src={playstoreImg}
//                         height="55"
//                         alt="Uygulamamızı Google Play'den İndir"
//                       />
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobPosting;

import playstoreImg from "@/images/get-play-store.png";
import appstoreImg from "@/images/get-app-store.svg";
import noAvatar from "../../images/company-logo-05.png";
import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import MultiRangeSlider from "@/components/MultiRangeSlider";
import { fetchPublicJobs } from "@/api/jobs";
import { toast } from "react-toastify";
import ReactTimeAgo from "react-time-ago";
import { fetchBusinesAreaOptions, fetchProvince } from "@/api/public";
import DropdownWithSearch from "@/components/DropdownWithSearch";
import { JOB_TYPE_OPTIONS } from "@/libs/constants";
import { debounce } from "lodash";

const JobPosting = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Şehir");
  const [selectedGender, setSelectedGender] = useState("Tümü"); // Default value
  const [jobs, setJobs] = useState([]);
  const [salaryRange, setSalaryRange] = useState<{ min: number; max: number }>({
    min: 100,
    max: 10000,
  });
  const [workingHours, setWorkingHours] = useState<{
    min: number;
    max: number;
  }>({
    min: 1,
    max: 24,
  });

  const [jTypeFullTime, setJTypeFullTime] = useState(false);
  const [jTypePartTime, setJTypePartTime] = useState(false);
  const [jTypeDaily, setJTypeDaily] = useState(false);
  const [selectedBusinessArea, setSelectedBusinessArea] = useState<any>(
    searchParams.get("role")
  );
  const [businessAreas, setBusinessAreas] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState<any>(
    searchParams.get("city")
  );

  useEffect(() => {
    const handleFetchProvinces = async () => {
      const provincesResponse = await fetchProvince();
      setProvinces(
        provincesResponse.data.provinces.map((province: any) => ({
          value: province.id,
          label: province.name,
        }))
      );
    };
    handleFetchProvinces();
  }, []);

  useEffect(() => {
    const handleFetchBusinessAreas = async () => {
      const businessAreasResponse = await fetchBusinesAreaOptions();
      setBusinessAreas(
        businessAreasResponse.data.areas.map((businessArea: any) => ({
          value: businessArea.id,
          label: businessArea.name,
        }))
      );
    };
    handleFetchBusinessAreas();
  }, []);

  useEffect(() => {
    console.log(
      "useEffect",
      workingHours,
      selectedGender,
      selectedValue,
      jTypeFullTime,
      jTypePartTime,
      jTypeDaily,
      selectedBusinessArea,
      selectedProvince,
      salaryRange
    );

    const handleSearch = async () => {
      let request: any = {
        province_id: selectedProvince ? selectedProvince : 80, // Eğer seçilen bir şehir varsa onu kullan
      };

      let jobTypes = [];
      if (jTypeFullTime) {
        jobTypes.push("FULL_TIME");
      }
      if (jTypePartTime) {
        jobTypes.push("PART_TIME");
      }
      if (jTypeDaily) {
        jobTypes.push("DAILY");
      }

      if (jobTypes.length > 0) {
        request["types"] = jobTypes;
      }

      if (selectedGender !== "Tümü") {
        request["gender"] = selectedGender === "Erkek" ? "MALE" : "FEMALE";
      }

      if (selectedBusinessArea) {
        request["business_area_id"] = selectedBusinessArea;
      }

      if (salaryRange.min && salaryRange.max) {
        request["salary_min"] = salaryRange.min;
        request["salary_max"] = salaryRange.max;
      }

      if (workingHours.min && workingHours.max) {
        if (workingHours.max == 24) {
          request["start_hour"] =
            (workingHours.min < 10
              ? "0" + workingHours.min
              : workingHours.min) + ":00:00";
          request["end_hour"] = "23:59:59";
        } else {
          request["start_hour"] =
            (workingHours.min < 10
              ? "0" + workingHours.min
              : workingHours.min) + ":00:00";
          request["end_hour"] =
            (workingHours.max < 10
              ? "0" + workingHours.max
              : workingHours.max) + ":00:00";
        }
      }
      try {
        const response = await fetchPublicJobs(request);

        if (response.status === "0") {
          setJobs(response.data.jobs);
        } else {
          toast.info(response.message);
          setJobs([]);
        }
      } catch (error) {
        toast.error("İş ilanlarını getirirken bir hata oluştu.");
      }
    };

    handleSearch();
  }, [
    selectedGender,
    selectedValue,
    jTypeFullTime,
    jTypePartTime,
    jTypeDaily,
    selectedBusinessArea,
    selectedProvince,
    salaryRange,
    workingHours,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGender(event.target.value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false); // Optionally close the dropdown when an option is selected
  };

  return (
    <div className="container padding-top-50">
      <div className="row">
        <div className="col-md-3">
          <div className="sidebar-container">
            <div className="sidebar-widget">
              <h3>Şehir</h3>
              <div className="sidebar-widget">
                <DropdownWithSearch
                  onSelect={(value) => setSelectedProvince(parseInt(value))}
                  selectedValue={selectedProvince}
                  options={provinces}
                  placeholder="Şehir"
                />
              </div>
            </div>

            <div className="sidebar-widget">
              <h3>Cinsiyet</h3>
              <div className="keywords-container">
                <div className="keyword-input-container">
                  <div className="radio padding-right-4">
                    <input
                      id="radio-1"
                      name="radio"
                      type="radio"
                      value="Tümü"
                      checked={selectedGender === "Tümü"}
                      onChange={handleChange}
                    />
                    <label htmlFor="radio-1">
                      <span className="radio-label"></span> Tümü
                    </label>
                  </div>
                  <div className="radio padding-right-4 mx-3">
                    <input
                      id="radio-2"
                      name="radio"
                      type="radio"
                      value="Erkek"
                      checked={selectedGender === "Erkek"}
                      onChange={handleChange}
                    />
                    <label htmlFor="radio-2">
                      <span className="radio-label"></span> Erkek
                    </label>
                  </div>
                  <div className="radio">
                    <input
                      id="radio-3"
                      name="radio"
                      type="radio"
                      value="Kadın"
                      checked={selectedGender === "Kadın"}
                      onChange={handleChange}
                    />
                    <label htmlFor="radio-3">
                      <span className="radio-label"></span> Kadın
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="sidebar-widget">
              <h3>Pozisyon</h3>
              <DropdownWithSearch
                selectedValue={selectedBusinessArea}
                onSelect={(value) => {
                  setSelectedBusinessArea(parseInt(value));
                }}
                options={businessAreas}
                placeholder="Pozisyon"
                label=""
              />
            </div>
            <div className="sidebar-widget">
              <h3>Çalışma Şekli</h3>
              <div className="switches-list">
                <div className="switch-container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setJTypeFullTime(e.target.checked);
                      }}
                    />
                    <span className="switch-button"></span> Tam Zamanlı
                  </label>
                </div>
                <div className="switch-container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setJTypePartTime(e.target.checked);
                      }}
                    />
                    <span className="switch-button"></span> Yarı Zamanlı
                  </label>
                </div>
                <div className="switch-container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setJTypeDaily(e.target.checked);
                      }}
                    />
                    <span className="switch-button"></span> Günlük
                  </label>
                </div>
              </div>
            </div>

            <div className="sidebar-widget">
              <h3>Saat/Ücret Aralığı</h3>
              <div className="margin-top-55"></div>
              <MultiRangeSlider
                min={100}
                max={10000}
                onChange={({ min, max }) => {
                  if (salaryRange.min !== min || salaryRange.max !== max)
                    setSalaryRange({ min, max });
                }}
              />
            </div>

            <div className="sidebar-widget">
              <h3>Çalışma Saatleri</h3>
              <div className="margin-top-55"></div>
              <MultiRangeSlider
                min={1}
                max={24}
                onChange={({ min, max }) => {
                  if (workingHours.min !== min || workingHours.max !== max)
                    setWorkingHours({ min, max });
                }}
              />
            </div>
            <div className="padding-bottom-25"></div>
          </div>
        </div>
        <div className="col-md-9 content-left-offset">
          <h3 className="page-title">Arama Sonuçları</h3>

          <div className="listings-container margin-top-35 margin-bottom-35">
            {jobs.map((job: any, index) => (
              <Link to={`/job/${job.id}`} className="job-listing" key={job.id}>
                <div className="job-listing-details">
                  <div className="job-listing-company-logo">
                    <img src={noAvatar} alt="" />
                  </div>
                  <div className="job-listing-description">
                    <h4 className="job-listing-company">
                      {job.company.name}{" "}
                      <span
                        className="verified-badge"
                        data-tippy-placement="top"
                        data-tippy=""
                        data-original-title="Doğrulanmış İşveren"
                      ></span>
                    </h4>
                    <h3 className="job-listing-title">
                      {job.business_area.name}
                    </h3>
                    <p className="job-listing-text">{job.description}</p>
                  </div>
                </div>
                <div className="job-listing-footer">
                  <ul>
                    <li>
                      <i className="icon-material-outline-location-on"></i>{" "}
                      {job.province.name}
                    </li>
                    <li>
                      <i className="icon-material-outline-business-center"></i>{" "}
                      {job.type
                        ? JOB_TYPE_OPTIONS[
                            job.type as keyof typeof JOB_TYPE_OPTIONS
                          ]
                        : ""}
                    </li>
                    <li>
                      <i className="icon-material-outline-access-time"></i>{" "}
                      <ReactTimeAgo date={job.updated_at} />
                    </li>
                  </ul>
                </div>
              </Link>
            ))}

            <div className="row">
              <div className="col-md-12">
                <div className="notification notice download">
                  <p>
                    Web sitemiz üzerinden en fazla <strong>3</strong> iş ilanı
                    görebilirsiniz.
                    <br />
                    Daha fazla iş teklifini görebilmek için{" "}
                    <strong>ParttimeJET</strong> uygulamasını indirmeniz
                    gerekmektedir.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="get-app">
                  <div className="get-app-center">
                    <a href="">
                      <img
                        src={appstoreImg}
                        height="55"
                        alt="Uygulamamızı Apple Store'den İndir"
                      />
                    </a>
                    <a href="">
                      <img
                        src={playstoreImg}
                        height="55"
                        alt="Uygulamamızı Google Play'den İndir"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPosting;
