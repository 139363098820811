import { ReactNode } from "react";
import { useMenu } from "@/contexts/MenuContext";
import MobileNav from "./MobileNav";
import Navbar from "./Navbar";
import Footer from "./Footer";
import BackToTop from "./BackToTop";

import Modals from "./Modals";
import { MODALS_LIST } from "@/libs/constants";
import { useShouldHideFooter } from "@/hooks";

const MainLayout = ({ children }: { children: ReactNode }) => {
  const { showMenu } = useMenu();
  const showFooter = useShouldHideFooter();
  return (
    <div>
      <MobileNav />
      <div
        id="wrapper"
        className="mm-page mm-slideout"
        style={showMenu ? { minHeight: "903px" } : {}}
      >
        <BackToTop />
        <Navbar />
        <div className="clearfix"></div>
        {children}
        {showFooter && (<Footer />)}
      </div>
      {/* Render FeedbackModal */}
      <Modals modals={MODALS_LIST} />
    </div>
  );
};

export default MainLayout;

