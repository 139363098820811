import React, { useEffect, useState } from "react";
import userAvatarPlaceholder from "@/images/user-avatar-big-01.jpg";
import trFlag from "@/images/flags/tr.svg";
import Dropdown from "@/components/Dropdown";
import {
  POSITION_OPTIONS,
  sortOptions,
  CandidateSearch_City,
} from "@/libs/constants";
import MultiRangeSlider from "@/components/MultiRangeSlider";
import { Link } from "react-router-dom";
import { searchCandidates } from "@/api/company";

// Utility to get current time in HH:MM:SS format
const getCurrentTimeFormatted = (): string => {
  const date = new Date();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

// Utility to add hours to current time
const addHoursToCurrentTime = (hoursToAdd: number): string => {
  const date = new Date();
  date.setHours(date.getHours() + hoursToAdd);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const SearchWorkspace = () => {
  const [only_photo_candidate, setOnlyPhotoCandidate] = useState(false);
  const [military_service, setMilitaryService] = useState(false);
  const [turkish_citizen, setTurkishCitizen] = useState(false);
  const [only_favorite_candidate, setOnlyFavoriteCandidate] = useState(false);
  const [only_verified_candidate, setOnlyVerifiedCandidate] = useState(false);
  const [experienced_candidate, setExperiencedCandidate] = useState(false);
  const [workingHours, setWorkingHours] = useState({ min: 1, max: 24 });
  const [driving_license, setDrivingLicense] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>(
    sortOptions[0].value
  );
  const [jTypeFullTime, setJTypeFullTime] = useState(false);
  const [jTypePartTime, setJTypePartTime] = useState(false);
  const [jTypeDaily, setJTypeDaily] = useState(false);
  const [candidates, setCandidates] = useState<any[]>([]); // State to store candidates
  const [city, setCity] = useState<number | null>(null); // Example state to track city selection
  const [position, setPosition] = useState<string | null>(null); // Example state to track position selection
  const [salaryRange, setSalaryRange] = useState<{ min: number; max: number }>({
    min: 100,
    max: 10000,
  });

  const handleSearch = async () => {
    let start_time:any = null;
    let end_time:any = null;
    if(workingHours.max == 24) {
      start_time = (workingHours.min < 10 ? "0" + workingHours.min : workingHours.min) + ":00:00";
      end_time = "23:59:59";
    }else{
      start_time = (workingHours.min < 10 ? "0" + workingHours.min : workingHours.min) + ":00:00";
      end_time = (workingHours.max < 10 ? "0" + workingHours.max : workingHours.max) + ":00:00";
    }

    try {
      const payload = {
        province_id: city || 0, // Assuming city is stored as an id
        business_area_id: position ? parseInt(position) : 0, // Assuming position is stored as a string id
        district_id: 0,
        age_min: 18,
        age_max: 35,
        start_time: start_time, // Dynamic current time
        end_time: end_time, // Dynamic end time, 4 hours later
        salary_min: salaryRange.min,
        salary_max: salaryRange.max,
        rating_min: 3,
        experienced_candidate,
        disabled_candidate: false,
        retired_candidate: false,
        driving_license,
        military_service,
        turkish_citizen,
        only_photo_candidate,
        only_verified_candidate,
        only_favorite_candidate
      };

      // Fetch candidates using the API function
      const response = await searchCandidates(payload);
      
      setCandidates(response.working_schedule_model); // Set candidates data in state
    } catch (error) {
      console.error("Error fetching candidates:", error);
    }
  };

  React.useEffect(() => {
    handleSearch();
  }, [city, position, salaryRange, workingHours, experienced_candidate, jTypePartTime, jTypeDaily, turkish_citizen, only_photo_candidate, only_verified_candidate, only_favorite_candidate, driving_license, military_service]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false); // Optionally close the dropdown when an option is selected
  };

  return (
    <div className="row">
      <div className="col-md-3">
        <div className="sidebar-container">
          {/* Sidebar filters */}
          <div className="sidebar-widget">
            <h3>Şehir</h3>
            <Dropdown
              options={CandidateSearch_City}
              placeholder="Şehir"
              label=""
              onSelect={(value) => setCity(Number(value))} // Set city from dropdown
            />
          </div>

          <div className="sidebar-widget">
            <h3>Cinsiyet</h3>
            <div className="keywords-container">
              <div className="keyword-input-container">
                <div className="radio padding-right-4">
                  <input
                    id="radio-1"
                    name="radio"
                    type="radio"
                    defaultChecked
                  />
                  <label htmlFor="radio-1">
                    <span className="radio-label"></span> Tümü
                  </label>
                </div>
                <div className="radio padding-right-4">
                  <input id="radio-2" name="radio" type="radio" />
                  <label htmlFor="radio-2">
                    <span className="radio-label"></span> Erkek
                  </label>
                </div>
                <div className="radio">
                  <input id="radio-3" name="radio" type="radio" />
                  <label htmlFor="radio-3">
                    <span className="radio-label"></span> Kadın
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="sidebar-widget">
            <h3>Yaş Aralığı</h3>
            <MultiRangeSlider
              min={18}
              max={65}
              onChange={({ min, max }) => {
                // You can track min and max age here if needed
              }}
            />
          </div>

          <div className="sidebar-widget">
            <h3>Pozisyon</h3>
            <Dropdown
              options={POSITION_OPTIONS}
              placeholder="Pozisyon"
              label=""
              onSelect={(value) => setPosition(value)} // Set position from dropdown
            />
          </div>
          <div className="sidebar-widget">
              <h3>Aday Seçimleri</h3>

              <div className="switches-list">
                

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setExperiencedCandidate(e.target.checked)}/>
                    <span className="switch-button"></span> Deneyimli Adaylar
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setJTypePartTime(e.target.checked)}/>
                    <span className="switch-button"></span> Engelli Adaylar
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setJTypeDaily(e.target.checked)}/>
                    <span className="switch-button"></span> Emekli Adaylar
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setTurkishCitizen(e.target.checked)}/>
                    <span className="switch-button"></span> T.C. Vatandaşları
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setOnlyPhotoCandidate(e.target.checked)}/>
                    <span className="switch-button"></span> Fotoğraflı Adaylar
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setOnlyPhotoCandidate(e.target.checked)}/>
                    <span className="switch-button"></span> Doğrulanmış Adaylar
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setJTypeDaily(e.target.checked)}/>
                    <span className="switch-button"></span> Favori Adaylar
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setDrivingLicense(e.target.checked)}/>
                    <span className="switch-button"></span> Ehliyeti Olan Adaylar
                  </label>
                </div>

                <div className="switch-container">
                  <label className="switch">
                    <input type="checkbox" onChange={(e) => setMilitaryService(e.target.checked)}/>
                    <span className="switch-button"></span> Askerliğini Yapmış Adaylar
                  </label>
                </div>
              </div>
            </div>

          <div className="sidebar-widget">
            <h3>Maaş Aralığı</h3>
            <MultiRangeSlider
min={100}
max={10000}
onChange={({ min, max }) => {
  if(salaryRange.min !== min || salaryRange.max !== max)
   setSalaryRange({ min, max })
}}            />
          </div>

          <div className="sidebar-widget">
            <h3>Çalışma Saatleri</h3>
            <MultiRangeSlider
              min={1}
              max={24}
              onChange={({ min, max }) => {
                if(workingHours.min !== min || workingHours.max !== max)
                 setWorkingHours({ min, max })
              }}
            />
          </div>

          <div className="col">
            <button
              className="button full-width button-sliding-icon ripple-effect margin-top-30"
              type="button"
              onClick={handleSearch} // Trigger search on button click
            >
              Arama Yap{" "}
              <i className="icon-material-outline-arrow-right-alt"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="col-md-9 content-left-offset">
        <h3 className="page-title">Aday Listesi</h3>


        {/* Freelancers List Container */}
        <div className="freelancers-container freelancers-list-layout margin-top-35">
          {candidates.length === 0 ? (
            <p>Aday listesi yükleniyor...</p>
          ) : (
            candidates.map((candidate) => (
              <div className="freelancer" key={candidate.id}>
                <div className="freelancer-overview">
                  <div className="freelancer-overview-inner">
                    {/* Bookmark Icon */}
                    <span className="bookmark-icon"></span>

                    {/* Avatar */}
                    <div className="freelancer-avatar">
                      {candidate.user.verified && (
                        <div className="verified-badge"></div>
                      )}
                      <a href="#">
                        <img
                          src={
                            candidate.user.avatar?.url || userAvatarPlaceholder
                          }
                          alt=""
                        />
                      </a>
                    </div>

                    {/* Name */}
                    <div className="freelancer-name">
                      <h4>
                        <a href="#">
                          {candidate.user.first_name} {candidate.user.last_name}{" "}
                          <img
                            className="flag"
                            src={trFlag}
                            alt=""
                            title="Türkiye"
                            data-tippy-placement="top"
                          />
                        </a>
                      </h4>
                      <span>{candidate.user.role}</span>
                      <div className="freelancer-rating">
                        {candidate.user.rate > 0 ? (
                          <div
                            className="star-rating"
                            data-rating={candidate.user.rate}
                          ></div>
                        ) : (
                          <span className="company-not-rated margin-bottom-5">
                            Henüz yeterli değerlendirme almadı
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Details */}
                <div className="freelancer-details">
                  <div className="freelancer-details-list">
                    <ul>
                      <li>
                        Şehir{" "}
                        <strong>
                          <i className="icon-material-outline-location-on"></i>{" "}
                          {candidate.locations[0]?.province?.name} /{" "}
                          {candidate.locations[0]?.district?.name}
                        </strong>
                      </li>
                      <li>
                        Ücret <strong>{candidate.min_salary}₺/saat</strong>
                      </li>
                    </ul>
                  </div>
                  <Link to={`/employer/FreelancerProfile/${candidate.user.id}`}>
                    <a
                      href="#"
                      className="button button-sliding-icon ripple-effect"
                    >
                      Profili İncele{" "}
                      <i className="icon-material-outline-arrow-right-alt"></i>
                    </a>
                  </Link>
                </div>
              </div>
            ))
          )}
        </div>

        {/* Pagination */}
        <div className="clearfix"></div>
        <div className="row">
          <div className="col-md-12">
            <div className="pagination-container margin-top-40 margin-bottom-60">
              <nav className="pagination">
                <ul>
                  <li className="pagination-arrow">
                    <a href="#" className="ripple-effect">
                      <i className="icon-material-outline-keyboard-arrow-left"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="current-page ripple-effect">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ripple-effect">
                      2
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ripple-effect">
                      3
                    </a>
                  </li>
                  <li className="pagination-arrow">
                    <a href="#" className="ripple-effect">
                      <i className="icon-material-outline-keyboard-arrow-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchWorkspace;
