import React from "react";
import trImg from "@/images/flags/tr.svg";
import useravatar from "@/images/user-avatar-big-01.jpg";
import noavatar from "@/images/no-avatar.png";
import bgImage from "@/images/single-job.jpg";

import SinglePageHeader from "./PageHeader/SinglePageHeader";
import FreelancerAboutMe from "./AboutMe/FreelancerAboutMe";
import FreelancerFeedback from "./Feedback/FreelancerFeedback";

import EmployementComponent from "./EmployementHistory/EmployementComponent";
import MakeAnOffer from "./MakeAnOffer/MakeAnOffer";
import Indicators from "./Indicators/Indicators";
import FreelancerSkills from "./FreelancerSkills/FreelancerSkills";
import FreelancerAttachments from "./FreelancerAttachments/FreelancerAttachments";
import Bookmark from "./Bookmark/Bookmark";
import { useParams } from "react-router-dom";
import { fetchFreelancer } from "@/api/public";
import { BarLoader } from "react-spinners";

const skills = [
  "iOS",
  "Android",
  "mobile apps",
  "design",
  "Python",
  "Flask",
  "PHP",
  "WordPress",
];

// const Attachment =

const FreelancerProfile = () => {
  const params = useParams() as any;
  const userid = params.id as any;
  const [freelancer, setFreelancer] = React.useState<any>();

  const handleFetchFreelancer = async () => {
    const response = await fetchFreelancer(userid);
    setFreelancer(response.data);
  }

  React.useEffect(() => {
    handleFetchFreelancer();
  }
  , []);

  if (!freelancer) {
   return <BarLoader color="#f7c08a" loading={true} width="100%" height={4} />
  }

  return (
    <>
      <SinglePageHeader
        username={freelancer.first_name + " " + freelancer.last_name}
        qualification="iOS Expert + Node Dev"
        rating={5.0}
        location="Türkiye"
        userAvatarSrc={freelancer.avatar.url ?? noavatar}
        userAvatarAlt={freelancer.first_name + " " + freelancer.last_name}
        countryFlagSrc={trImg}
        countryFlagAlt="Turkey Flag"
        isVerified={true}
        backgroundImageSrc={bgImage}
      />

      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 content-right-offset">
            {/* <!-- Page Content --> */}
            <FreelancerAboutMe
              aboutMe={freelancer.bio} />

            {/* <!-- Boxed List --> */}
            {/* <FreelancerFeedback /> */}
            {/* <!-- Boxed List / End --> */}

            {/* <!-- Boxed List --> */}

            <EmployementComponent jobHistoryData={freelancer.history || []} />

            {/* <!-- Boxed List / End --> */}
          </div>
          {/* 
      <!-- Sidebar --> */}
          <div className="col-xl-4 col-lg-4">
            <div className="sidebar-container">
              {/* <!-- Profile Overview --> */}

              <MakeAnOffer hourlyrate={freelancer.hourly_salary + " "} jobscount={freelancer.total_jobs ?? 0} rehiredcount="0" />

              {/* <!-- Freelancer Indicators --> */}

              {/* <Indicators
                jobSuccessPercentage={88}
                recommendationPercentage={95}
                onTimePercentage={90}
                onBudgetPercentage={85}
              /> */}

              {/* <!-- Widget --> */}

              <FreelancerSkills skills={freelancer.skills || []} />

              {/* <!-- Widget --> */}

              {/* <div className="sidebar-widget">
                <h3>Attachments</h3>
                <div className="attachments-container">
                  <a href="#" className="attachment-box ripple-effect">
                    <span>Cover Letter</span>
                    <i>PDF</i>
                  </a>
                  <a href="#" className="attachment-box ripple-effect">
                    <span>Contract</span>
                    <i>DOCX</i>
                  </a>
                </div>
              </div> */}
              <br />
              {/* <FreelancerAttachments /> */}

              {/* <Bookmark /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreelancerProfile;
